import React from 'react';
import Typography from '@material-ui/core/Typography';
import { NUGETFRAMEWORK_WRAPPER, NUGETFRAMEWORK_AUTH, NUGETCORE_REGISTER, NUGETCORE_AUTH, NUGET_WRAPPER } from '../../../../Common/Constants/constants';
import CopySnippet from '../CopySnippet/CopySnippet';
import useDeveloperTabStyles from './DeveloperTabStyles';
import appConfig from '../../../../Environment/environments';

export default function NugetTab() {
    const { instructions, refLink } = useDeveloperTabStyles();
    return (
        <div className={instructions}>
            <Typography><b>TokenValidation</b> is an internal NuGet package to verify the JWT token provided by CarrierAppsHub .NET applications.</Typography> <br />
            <Typography>
                In order to implement authentication, an internal nuget package has to be downloaded. To install the package, internal nuget source needs has to be added by following these steps -
                Go to <i>Tools</i> menu -> Package Manager -> Package Manager Settings -> Package Sources -> Add(+) -> Provide package name and source as <u>https://scejf01.apps.carrier.com/artifactory/api/nuget/nuget</u>
            </Typography>
            <br />
            <Typography>Once nuget package source setting is added, its time to install the package. Perform right click on the project that needs to implement authentication and select <i>Manage Nuget Packages</i>. Search for <i>TokenValidation</i> package and install.</Typography>
            <br />
            <Typography>Below are the steps to integrate TokenValidation package with .NET Core and .NET Framework application.</Typography>
            <br />
            <Typography><b><u>.NET Core</u></b></Typography>
            <Typography><b>Step 1: </b>Add required configuration in Appsettings.json</Typography>
            <CopySnippet copyCode= {NUGETCORE_AUTH.replace("@azureIss", appConfig.api.authSettings.azureIss).replace("@awsIss", appConfig.api.authSettings.awsIss) } />
            <Typography><b>Step 2: </b>Register AuthSettings in Startup.cs</Typography>
            <CopySnippet copyCode={NUGETCORE_REGISTER} />
            <Typography><b>Step 3: </b>Add authentication filter in controller</Typography>
            <CopySnippet copyCode={NUGET_WRAPPER} />
            <br />
            <Typography><b><u>.NET Framework</u></b></Typography>
            <Typography><b>Step 1: </b>Add required configuration in AppSettings section of Web.Config file</Typography>
            <CopySnippet copyCode= {NUGETFRAMEWORK_AUTH.replace("@azureIss", appConfig.api.authSettings.azureIss).replace("@awsIss", appConfig.api.authSettings.awsIss) } />
            <Typography><b>Step 2: </b>Add authentication filter in controller</Typography>
            <CopySnippet copyCode={NUGETFRAMEWORK_WRAPPER} />
            <div>
                For further details refer the link -
                <span>
                    <a className={refLink} target="_blank" href="https://scegithub.apps.carrier.com/SCE/TokenValidation">TokenValidation Nuget</a>
                </span>
            </div>
        </div>
    );
}
