import React from 'react';
import Button from '@material-ui/core/Button';
import { CustomIcon } from "../CustomIcon";
import customFilterButtonStyles from "./customFilterButtonStyles";
import { EDIT } from '../../../Common/Constants/constants';
import clsx from 'clsx';

export const CustomFilterButton = (props) => {
    const { isButtonDisabled = true, isEditMode = false, buttonLabel = "", onClick, onDelete, onEdit } = props;
    const iconType = "delete_rounded";
    const customProps = { isEditMode: isEditMode };
    const { filterButton, filterEdit } = customFilterButtonStyles(customProps);
    const onClickHandler = (event) => {
        onClick && onClick(event);
    }
    const onDeleteHandler = (event) => {
        event.stopPropagation();
        onDelete && onDelete(event);
    }
    const onEditHandler = (event) => {
        onEdit && onEdit(event);
    }
    return (
        <>
            <Button
                disabled={isButtonDisabled}
                variant="contained"
                disableRipple
                classes={{ root: filterButton }}
                onClick={onClickHandler} >
                {buttonLabel}
                {isEditMode && <CustomIcon onClick={onDeleteHandler} iconType={iconType} />}
            </Button>
            {isEditMode &&
                <Button
                    disabled={isButtonDisabled}
                    variant="contained"
                    disableRipple
                    classes={{ root: clsx(filterEdit,filterButton) }}
                    onClick={onEditHandler} >
                    {EDIT}
                </Button>
            }
        </>
    )
}