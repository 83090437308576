import appConfig from '../../../Environment/environments';
import axiosInstance from './interceptor';

const header = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

const getHeader = (type) => {
    return {
        method: type,
        headers: header
    }
}

const apiGet = async(url, failureCallback) => {
    try {
        return await axiosInstance.get(`${appConfig.api.development}${url}`,getHeader('GET'));
    }
    catch(error) {
        failureCallback && failureCallback(error); 
    }
}

const apiPost = async(url, dataObj=null, failureCallback) => {
    try {
        return await axiosInstance.post(`${appConfig.api.development}${url}`,dataObj,getHeader('POST'));
    }
    catch(error) {
        failureCallback && failureCallback(error); 
    }
}

const apiPut = async(url, dataObj=null, failureCallback) => {
  try {
      return await axiosInstance.put(`${appConfig.api.development}${url}`,dataObj,getHeader('PUT'));
  }
  catch(error) {
      failureCallback && failureCallback(error); 
  }
}

export {apiGet,apiPost,apiPut};