import React, { memo, useState, useEffect } from 'react';
import './AuditGrid.css';
import AuditTable from '../Audit/AuditTable';
import {
  DELETEADMIN, DELETEUSER, REFRESH, DELETEADMINDIALOG, DELETEUSERDIALOG,
  GET_GROUP_AUDIT, LOADINGTEXT, NORECORDFOUND } from '../../../Common/Constants/constants';
import { CustomButton } from "../CustomButton/CustomButton";
import userStatsFilterStyles from "../Insights/UserStatsFilter/UserStatsFilterStyles";
import {apiGet,apiPost} from '../../Shared//Interceptor/apiService';
import { Pagination } from "@material-ui/lab";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const AuditGrid = (props) => {
  const { onContentChange, adminData, reloadAllUsers, maxAdminCount, adminCount,
    groupId, initialRender, gridType, type, isTimeout, nextTimeout, onDeleteAdmin,parentEditGroup } = props;
  const { userStatsFilterContainer, userStatsContainer, userStatsGroupName, filterHeader,
    userStatsReload, filterContainer, filterContent, userStatsButtonContainer } = userStatsFilterStyles();
  const dialogHead = (gridType === 0) ? DELETEADMIN : DELETEUSER;
  const dialogContent = (gridType === 0) ? DELETEADMINDIALOG : DELETEUSERDIALOG;
  const [auditData, setAuditData] = React.useState([]); 
  const [loading, setLoading] = React.useState([false]);
  const [perPage, setPerPage] = React.useState(10);
  const perPageList = [5, 10, 20, 50];
  const [currentPageNo, setCurrentPageNo] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [refreshButton, setRefreshButton] = React.useState(true);

  useEffect(() => {
    getAuditData(groupId, auditSuccessCallback);
  }, [currentPageNo, perPage]);

  const formatAuditData = (data) => {
    data.forEach(element => {
        if (element.createdDate != null) {
          var createdDate = new Date(element.createdDate);
          element.createdDate = createdDate.toLocaleString();
        }
        
        if (element["affectedUsers"] != null) {
          const attribs = element["affectedUsers"].split('|');
          if(attribs.length > 1){
            element["email"] = attribs[0];
            element["username"] = attribs[1];
            //element["userType"] = attribs[2];
          }else{
            element["email"] = element["affectedUsers"];
          }
        }

        if (element["createdBy"] != null) {
          const attribs = element["createdBy"].split('|');
          if(attribs.length > 1){
            element["adminEmail"] = attribs[0];
            element["adminName"] = attribs[1];
            //element["adminUserType"] = attribs[2];
          }else{
            element["adminEmail"] = element["createdBy"];
          }
        }
    });
    return data;
  }

  const getAuditData = async (groupId = "", successCallback, failureCallback) => {
    setRefreshButton(true);
    if (groupId) {
      const auditObj = JSON.stringify({
        AffactedEntityId: groupId,
        PageNo: currentPageNo,
        PageSize: perPage     
      });
      setLoading(true);
      const auditResponse = await apiPost(GET_GROUP_AUDIT, auditObj, failureCallback);
      setLoading(false);
      setAuditData(formatAuditData(auditResponse.data));
      if(auditResponse.data.length > 0)
        setPageCount(Math.ceil(auditResponse.data[0].totalCount / perPage));
    }
    setRefreshButton(false);
  }

  const auditSuccessCallback = (auditResponse) => {
    return auditResponse;
  }

  const onReloadButtonClick = () => {
    setCurrentPageNo(0);
    getAuditData(groupId);
  }

  const handlePageChange = (event, value) => {
    setCurrentPageNo(value);
  };

  const handlePerPageChange = (event) => {
    setCurrentPageNo(0);
    setPerPage(event.target.value);
  }

  const closeParentEditGroup=(value)=>{
      parentEditGroup(value)
  }

  return (
    <div className="App">
      <div>
        <div style={{"margin-left": "90%"}}>
          <CustomButton buttonLabel={REFRESH} isButtonDisabled={refreshButton} onClick={onReloadButtonClick} />
          {/* <div className={userStatsReload}>
            
          </div> */}
        </div>
      </div>  
    { loading ? 
    <div className="noUsersInfo">
      <span className="noUsersContent">{LOADINGTEXT}</span>
    </div> : 
    auditData &&
    <>
      <AuditTable
        onContentChange={onContentChange}
        items={auditData}
        groupId={groupId}
        reloadAllUsers={reloadAllUsers}
        initialRender={initialRender}
        gridType={gridType}
        type={type}
        dialogContent={dialogContent}
        dialogHead={dialogHead}
        perPage={perPage}
        getAuditData={getAuditData}
        closeParentEditGroup={closeParentEditGroup}
         />
      {(auditData.length > 0)  ? <div className="paginationDiv">
        <Pagination 
          count={pageCount} 
          size="large"
          page={(currentPageNo == 0)? 1 : currentPageNo}
          variant="outlined"
          shape="rounded" 
          onChange={handlePageChange} />
        <div className="selectDiv">
          <span>Items per page</span>
          <span style={{marginLeft:5 + "px"}}>
          <Select value={perPage} onChange={handlePerPageChange}>
            {perPageList.map((value, index) => {
              return <MenuItem value={value}>{value}</MenuItem>;
            })}
          </Select>
          </span>
        </div>
      </div> : <></>}
      
    </>
    }
    </div>
  );
}

export default AuditGrid;
