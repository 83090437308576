import React,{memo,useEffect,useReducer} from "react";
import {createInsightsData,insightsReducer,init,initialData} from "./InsightsDataHandler";
import UserAuthPie from "./UserAuthPie/UserAuthPie";
import UserActivePie from "./UserActivePie/UserActivePie";
import UserAuthChart from "./UserAuthChart/UserAuthChart";
import UserInsights from "./UserInsights/UserInsights";
import UserAvgLoginChart from "./UserAvgLoginChart/UserAvgLoginChart";
import UserStatsFilter from "./UserStatsFilter/UserStatsFilter";
import TabContainer from "../TabContainer";
import {apiPost} from '../Interceptor/apiService';
import {LOADINGTEXT,GET_ALL_USER_STATUS} from '../../../Common/Constants/constants';
import { makeStyles } from '@material-ui/core/styles';

const insightStyles = makeStyles((theme) => ({
    insightsLoading: {
        marginLeft: "11px"
    }
}));

const Insights = (props) => {
    const {groupName, groupId} = props;
    const [state, dispatch] = useReducer(insightsReducer, initialData, init);
    const {userStats,authStats,loading,groupStats, groupLoginStats, activeStats } = state;
    const {insightsLoading} = insightStyles();

    useEffect(() => {
        getInsightsData(groupId, [], "1", insightsSuccessCallback);
    },[])

    const getInsightsData = async(groupId="", apps=[], selectedGroupsOption="1", successCallback, failureCallback) => {
        if(groupId) {
            const insightObj = JSON.stringify({
                GroupId:groupId,
                Apps:apps,
                SelectedGroupOption:selectedGroupsOption
            });
            const insightsData = await apiPost(GET_ALL_USER_STATUS,insightObj,failureCallback);
            successCallback && successCallback(insightsData.data);
        }
    }

    const insightsSuccessCallback = (insightsInfo) => {
        const { groupStats, groupLoginStats, userStats,authStats, activeStats } = createInsightsData(insightsInfo);
        dispatch({type: 'updateUserStats', payload: { groupStats, groupLoginStats, userStats, authStats, activeStats } })
    }

    const onReloadButtonClick = (id=groupId,apps=[],groupSelectionType="1") => {
        dispatch({type: 'reset'});
        getInsightsData(id, apps, groupSelectionType, insightsSuccessCallback);
    }

    return (
        <>
            <UserStatsFilter
                groupName={groupName}
                groupId={groupId}
                onReloadButtonClick={onReloadButtonClick}
                isButtonDisabled={loading}
            />
            <TabContainer>
                {loading
                    ?
                    <span className={insightsLoading}>{LOADINGTEXT}</span>
                    :
                    <>
                        <UserAuthPie authStats={authStats} />
                        <UserActivePie authStats={activeStats} />
                        <UserInsights userStats={userStats} />
                        <UserAuthChart groupStats={groupStats} />
                        <UserAvgLoginChart groupLoginStats={groupLoginStats} />
                    </>
                }
            </TabContainer>
        </>
    )
};

export default memo(Insights);