import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ManageGroupStyles, GROUPSTEXT, DEVELOPERTAB, ADMINSTRATORS, PERMISSIONSTAB, TEMPLATETAB, CONFIGURETAB } from '../../../Common/Constants/constants';
import '../../../Assests/Styles/Styles.scss';
import '../../Groups/manageUsers/manageUsers.scss';
import DeveloperTab from './DeveloperTab/DeveloperTab';
import TemplateTab from './TemplateTab/TemplateTab';
import AdminTab from './AdminTab';
import GroupsTab from './GroupsTab';
import PermissionTab from './PermissionTab/PermissionTab';
import TabContainer from "../../Shared/TabContainer";
import CustomTabs from "../../Shared/CustomTabs/CustomTabs";
import ConfigureTab from "./Configure/ConfigureTab"

class EditAppTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      //tabLabels: [GROUPSTEXT, DEVELOPERTAB, ADMINSTRATORS, PERMISSIONSTAB]
      tabLabels: [GROUPSTEXT, DEVELOPERTAB, ADMINSTRATORS, PERMISSIONSTAB, TEMPLATETAB,CONFIGURETAB]
    };
  }

  appsTabs = (selectedTab) => {
    console.log(this.props.apiKey)
    const {appId, appName, appUrl,appClientId,apiKey,defaultGroupId} = this.props;
    switch (selectedTab) {
      case 0: return <div className="developerFieldsContainer"><GroupsTab Id={appId} /></div>
        break;
      case 1: return <TabContainer><DeveloperTab apiKey={apiKey} Id={appId}  appUrl={appUrl}/></TabContainer>
        break;
      case 2: return <div className="developerFieldsContainer"><AdminTab Id={appId} /></div>
        break;
      case 3: return <div className="developerFieldsContainer"><PermissionTab Id={appId} appName={appName} /></div>
        break;
      case 4: return <TemplateTab Id={appId} appUrl={appUrl} appClientId={appClientId}/>
        break;
      case 5: return <ConfigureTab Id={appId} defaultGroupId={defaultGroupId} />
        break;
      default: return null;
    }
  }

  handleChange = (event, value) =>
    this.setState({
      value
    });

  render() {
    const {classes} = this.props;
    const {value,tabLabels} = this.state;
    return (
      <div className={classes.root}>
        <CustomTabs 
          showButton={false}
          handleChange={this.handleChange}
          tabLabels={tabLabels}
          defaultSelected={value} />
        {this.appsTabs(value)}
      </div>
    );
  }
}

EditAppTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(ManageGroupStyles)(EditAppTabs);
