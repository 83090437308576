import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import DialogBox from "../../Shared/OptionsMenu/dialog";
import IconButton from "@material-ui/core/IconButton";
import Card from '@material-ui/core/Card';
import GetAppIcon from '@material-ui/icons/GetApp';
import CardContent from "@material-ui/core/CardContent";
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import { ReactComponent as EditClose } from '../../../Assests/Images/EditGroupClose.svg';
import ToastMessages from '../../Shared/Toast/ToastMessages';
import {
  EditGroupStyles, DEVURLDISCLAIMER, GROUPLENGTH50, DEVURL, ADDEDITAPP, GETAPPDETAILSFAIL, GETAPPDETAILS, UNSAVEDCHANGESHEADERTEXT, DELETEAPPHEADER, DELETEAPPCONTENT, SAVETEXT, UNSAVEDCHANGESDESCRIPTION, INVALIDURL, DASHBOARD, FIELDREQ, GROUPLENGTH,
  REQUIREDFIELDDISCLAIMERTEXT, NEWAPP, EDITAPP, APPUPDATESUCCESS, APPCREATESUCCESS, APPPERMISSIONNAME, APPSAVEERROR, APPALREADYEXISTERROR, APPURLALREADYEXISTERROR, APPNOCHANGES, CREATEAPP, APPDELETE, PREVIEW, CONTINUETEXT, APPNAME, URL, IMAGE, UPLOAD, IMGSIZEERROR, IMGTYPEERROR, IMGRESERROR, IMGUPLOADDISCLAIMER,
  SYSTEMTOPLEVEL, PARENTAPPLICATION, PARENTAPPLICATIONSELECT, GETSYSTEMAPPS, GETCATEGORY, CATEGORYCONTROL, PARENTAPPLICATIONVALUE,MAPDEFAULTGROUP
} from '../../../Common/Constants/constants';
import CustomTextField from '../../Groups/CustomTextField';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import { withRouter } from 'react-router';
import DeletePopUp from '../../Shared/OptionsMenu/DeletePopUp';
import { encryptData, downloadImage, getImageUrl } from '../../../Common/Utilities/utility';
import Grid from '@material-ui/core/Grid';
import CategoryDetails from './CategoryDetails';
import { CustomCheckbox } from "../../Shared/CustomCheckBox";

const styles = EditGroupStyles;

class AppDetails extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    statusMessage: false,
    imageUploading: false,
    message: '',
    toastVPosition: 'top',
    toastHPosition: 'left',
    messageVariant: '',
    changed: false,
    loggedInUserType: '',
    opennow: false,
    fields: [
      { "fieldName": APPNAME, "error": null, value: "", changed: false, originalValue: "", disabled: false },
      { "fieldName": URL, "error": null, value: "", changed: false, originalValue: "", disabled: false },
      { "fieldName": DEVURL, "error": null, value: "", changed: false, originalValue: "", disabled: false },
      { "fieldName": APPPERMISSIONNAME, "error": null, value: "", changed: false, originalValue: "", disabled: false }
    ],
    imageBase64Data: '',
    imageType: '',
    imageName: '',
    fileName: '',
    imageURL: '',
    imageUploadError: '',
    imageChanged: false,
    parentId: PARENTAPPLICATIONVALUE,
    parentApplicationChanged: false,
    parentValue: "",
    appValues: [],
    categoryList: [],
    categoryId: null,
    categoryValue: null,
    categoryChanged: false,
    categoryError: '',
    isDefaultMapped:null,
    isAppMappedChanged:false,
    apiKey:null
  }

  componentDidMount() {
    ((this.props.appId !== '' && this.props.type === 'edit') || (this.props.location && this.props.location.appId !== '' && this.props.location.type === 'edit')) && this.getAppDetails(this.props.appId);
    if (this.props.type !== 'edit') {
      this.getSystemApps("");
    }
    this.getCategoryList();
  }

  getSystemApps = (name) => {
    axiosInstance.get(`${appConfig.api.development}${GETSYSTEMAPPS}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (name === "") {
          this.setState({ appValues: res.data })
        }
        else {
          const newData = res.data.filter((item) => item.name !== name);
          this.setState({ appValues: newData })
        }
      })
      .catch(e => {
        console.log({ e });
      });
  }

  getCategoryList = () => {
    axiosInstance.get(`${appConfig.api.development}${GETCATEGORY}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        this.setState({ categoryList: res.data })
      })
      .catch(e => {
        console.log({ e });
      });
  }

  getAppDetails = (appId) => {
    axiosInstance.get(`${appConfig.api.development}${GETAPPDETAILS}${encryptData(appId)}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        let fieldData = this.state.fields;
        let devURL = res.data.devURL;
        fieldData[0].value = res.data.name;
        fieldData[0].originalValue = res.data.name;
        fieldData[1].value = res.data.url;
        fieldData[1].originalValue = res.data.url;
        fieldData[2].value = devURL;
        fieldData[2].originalValue = devURL;
        fieldData[3].value = res.data.appDesc;
        fieldData[3].originalValue = res.data.appDesc;
        this.setState({
          imageURL: `data:image/${res.data.logoImageType};base64,${res.data.logo}`,
          fields: fieldData,
          imageType: res.data.logoImageType,
          imageName: res.data.logoImageName,
          imageBase64Data: res.data.logo,
          parentValue: res.data.parentId,
          parentId: res.data.parentId,
          categoryValue: res.data.categoryId,
          categoryId: res.data.categoryId,
          parentApplicationChanged: false,
          categoryChanged: (res.data.categoryId === null) ? true : false,
          isDefaultMapped:(res.data.isDefault === true) ? res.data.isDefault : null,
          apiKey:res.data.apiKey
        }, () => {
          (this.props.type === 'edit') && this.props.setAppName(res.data);
          this.getSystemApps(res.data.appDesc);
        });
      })
      .catch(e => {
        this.setState({
          statusMessage: true,
          message: GETAPPDETAILSFAIL,
          messageVariant: "error",
        });
      });
  }

  isValuesChangd = () => {
    return this.state.fields[0].changed || this.state.fields[1].changed || this.state.fields[3].changed || this.state.fields[2].changed || this.state.imageChanged || this.state.parentApplicationChanged || this.checkCategory() || this.state.isAppMappedChanged
  }

  onCloseHandler = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    else if (this.state.imageChanged || this.isValuesChangd() || (this.state.categoryValue !== this.state.categoryId)) {
      this.setState({
        opennow: true
      });
    }
    else {
      this.props.history.push({
        pathname: DASHBOARD
      });
    }
  }

  checkCategory = () => {
    let value = "";
    if ((this.state.parentId === PARENTAPPLICATIONVALUE) && ((this.state.categoryId === null) || (this.state.categoryValue !== this.state.categoryId))) {
      value = true
    }
    else {
      value = false
    }
    return value;
  }

  removeDialogPopup = () => {
    this.setState({ opennow: false });
  }

  categoryHandler = () => {
    this.setState({
      categoryError: (this.state.categoryId === null) ? FIELDREQ : "",
      categoryChanged: (this.state.parentId === PARENTAPPLICATIONVALUE && this.state.categoryId === null) ? true : false
    });
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    let obj;
    this.fieldChangeHandler(this.state.fields[0].value, 0);
    this.fieldChangeHandler(this.state.fields[1].value, 1);
    this.fieldChangeHandler(this.state.fields[2].value, 2);
    this.fieldChangeHandler(this.state.fields[3].value, 3);
    if ((this.state.parentId === PARENTAPPLICATIONVALUE) && (this.state.categoryId === null)) {
      this.categoryHandler();
    } else {
      if (!this.isValuesChangd() && (this.props.type == 'edit')) {
        this.setState({
          statusMessage: true,
          message: APPNOCHANGES,
          messageVariant: "warning",
        });
      }
      else if (this.state.imageBase64Data === '' && (this.props.type == 'add')) {
        this.setState({
          imageUploadError: FIELDREQ
        });
      }
      else if (this.state.fields[0].error === null && this.state.fields[1].error === null && this.state.fields[2].error === null && this.state.fields[3].error === null) {
        if (this.props.type == 'edit')
        {
           obj = {
            "name": this.state.fields[0].value,
            "logo": this.state.imageBase64Data,
            "logoImageType": this.state.imageType,
            "url": this.state.fields[1].value,
            "logoImageName": this.state.fields[0].value,
            "appDesc": this.state.fields[3].value,
            "devURL": (this.state.fields[2].value === "") ? null : this.state.fields[2].value,
            "parentId": this.state.parentId,
            "categoryId": (this.state.parentId !== PARENTAPPLICATIONVALUE) ? null : this.state.categoryId,
            "isDefault":this.state.isDefaultMapped
          };
        }
        else{
           obj = {
            "name": this.state.fields[0].value,
            "logo": this.state.imageBase64Data,
            "logoImageType": this.state.imageType,
            "url": this.state.fields[1].value,
            "logoImageName": this.state.fields[0].value,
            "appDesc": this.state.fields[3].value,
            "devURL": (this.state.fields[2].value === "") ? null : this.state.fields[2].value,
            "parentId": this.state.parentId,
            "categoryId": (this.state.parentId !== PARENTAPPLICATIONVALUE) ? null : this.state.categoryId,
          };

        }
        
        let data;
        let request;
        if (this.props.type == 'edit') {
          obj.Id = this.props.appId;
          data = JSON.stringify(obj);
          request = axiosInstance.put(`${appConfig.api.development}${CREATEAPP}/${this.props.appId}`, data,
            {
              method: 'PUT',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              }
            });
        }
        else {
          data = JSON.stringify(obj);
          request = axiosInstance.post(`${appConfig.api.development}${CREATEAPP}`, data,
            {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              }
            });
        }

        request.then(res => {
          let parentVal = this.state.parentId;
          let categoryVal = this.state.categoryId;
          let fieldData = this.state.fields;
          fieldData[0].value = fieldData[0].originalValue = fieldData[0].value;
          fieldData[0].changed = false;
          fieldData[1].value = fieldData[1].originalValue = fieldData[1].value;
          fieldData[1].changed = false;
          fieldData[2].value = fieldData[2].originalValue = fieldData[2].value;
          fieldData[2].changed = false;
          fieldData[3].value = fieldData[3].originalValue = fieldData[3].value;
          fieldData[3].changed = false;
          this.setState({
            statusMessage: true,
            message: (this.props.type == 'edit') ? APPUPDATESUCCESS : APPCREATESUCCESS,
            messageVariant: "success",
            fields: fieldData,
            imageChanged: false,
            parentId: parentVal,
            categoryId: categoryVal,
            categoryValue: categoryVal,
            parentApplicationChanged: false,
            isAppMappedChanged:false
          }, () => {
            (this.props.type !== 'edit') && this.redirectToEditApp(res.data);
            (this.props.type === 'edit') && this.props.setAppName(res.data)
          });
        })
          .catch((e) => {
            let errorMessage = (e.response.data.message.indexOf("App name cannot be duplicated") > -1) ? APPALREADYEXISTERROR : (e.response.data.message.indexOf("App Url cannot be duplicated") > -1) ? APPURLALREADYEXISTERROR : APPSAVEERROR;
            this.setState({
              statusMessage: true,
              message: errorMessage,
              messageVariant: "error",
            });
          });
      }
    }
  }

  handleToast(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      statusMessage: false
    });
  }

  fieldChangeHandler = (targetValue, index) => {
    let fieldData = this.state.fields;
    fieldData[index].changed = (this.state.fields[index].originalValue !== targetValue) ? true : false;
    fieldData[index].value = targetValue && targetValue.trimStart();
    this.setState({
      fields: fieldData
    }, () => {
      this.validateField(index);
    });
  };

  isUrl = (url, isDev = false) => {
    const httpsLocalRegExp = /^(https):\/\/(((localhost)(:+)([0-9])*)|(localhost\/|localhost$))/;
    const regexp = (isDev) ? /(http):\/\/(localhost)(:[0-9]+)(\w*)/ : /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(url) && !(httpsLocalRegExp.test(url));
  }

  validateField = (index) => {
    let fieldData = this.state.fields;
    if (index === 0) {
      fieldData[index].error = ((fieldData[index].value == "") ? FIELDREQ : (fieldData[index].value.length > 100 ? GROUPLENGTH : null));
    }
    else if (index === 3) {
      fieldData[index].error = ((fieldData[index].value == "") ? FIELDREQ : (fieldData[index].value.length > 50 ? GROUPLENGTH50 : null));
    }
    else if (index === 2) {
      if (fieldData[index].value === "" || fieldData[index].value === null) {
        fieldData[index].value = null;
        fieldData[index].error = null;
      }
      else {
        let devURLs = fieldData[index].value.split(';');
        let urlValidity = false;
        (devURLs[(devURLs.length - 1)] === "") && devURLs.pop();
        let trailingSemiColonRegEx = /;$/
        devURLs.map(value => {
          urlValidity = (value === "") ? urlValidity : this.isUrl(value, true)
        }, this);
        let semiColonTest = (devURLs.length === 1) ? true : (trailingSemiColonRegEx.test(fieldData[index].value) === true);
        fieldData[index].error = (urlValidity && semiColonTest) ? null : INVALIDURL;
      }
    }
    else {
      fieldData[index].error = ((fieldData[index].value == "") ? FIELDREQ : (fieldData[index].value.length > 100 ? GROUPLENGTH : (this.isUrl(fieldData[index].value) ? null : INVALIDURL)));
    }
    ((this.props.type === 'edit') && (index === 0)) && this.props.setAppName(fieldData);
    this.setState({
      fields: fieldData
    });
  }

  showUpload = () => {
    document.getElementById('file').click()
  }

  fileUploadImageLoadCallback = (imgwidth, imgHeight) => {
    if (imgwidth > 300 || imgHeight > 300) {
      this.setState({
        imageBase64Data: '',
        imageURL: '',
        imageUploadError: IMGRESERROR,
        imageType: '',
        fileName: '',
        imageChanged: (this.props.type === 'edit') ? true : false
      });
    }
  }

  clearFileContent = (e) => {
    e.target.value = '';
  }

  onfileUpload = (e) => {
    this.img = new Image();
    this.fileSizeCheck = 0;
    this.fileTypeCheck = false;
    this.fileSizeCheck = (e.target.files[0]) ? (e.target.files[0].size <= 30000) : false;
    this.fileTypeCheck = (e.target.files[0]) ? ((e.target.files[0].type === "image/jpeg") || (e.target.files[0].type === "image/png") || (e.target.files[0].type === "image/bmp")) : false;
    this.fileType = (this.fileSizeCheck && this.fileTypeCheck) ? e.target.files[0].type.split('/')[1] : '';
    this.fileName = (this.fileSizeCheck && this.fileTypeCheck) ? e.target.files[0].name : '';
    let reader = new FileReader();
    let URL = window.URL || window.webkitURL;
    this.imageURL = (e.target.files[0]) ? URL.createObjectURL(e.target.files[0]) : '';
    let _this = this;
    if (this.fileSizeCheck && this.fileTypeCheck) {
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = function () {
        _this.setState({
          imageURL: _this.imageURL,
          imageBase64Data: reader.result.split(',')[1],
          imageUploadError: '',
          imageType: _this.fileType,
          fileName: _this.fileName,
          imageChanged: true
        });
      }
    }
    else if (this.fileSizeCheck === false) {
      this.setState({
        imageBase64Data: '',
        imageURL: '',
        imageUploadError: IMGSIZEERROR,
        imageType: '',
        fileName: '',
        imageChanged: (this.props.type === 'edit') ? true : false
      });
    }
    else if (this.fileTypeCheck === false) {
      this.setState({
        imageBase64Data: '',
        imageURL: '',
        imageUploadError: IMGTYPEERROR,
        imageType: '',
        fileName: '',
        imageChanged: (this.props.type === 'edit') ? true : false
      });
    }

    this.img.onload = function () {
      _this.fileUploadImageLoadCallback(this.width, this.height);
    }

    this.img.src = (e.target.files[0]) ? URL.createObjectURL(e.target.files[0]) : '';
  }

  redirectToDashboard = () => {
    if (this.props.appId !== '' && this.props.type === 'edit') {
      this.onCloseHandler();
    }

    if (this.props.location && this.props.location.appId !== '' && this.props.location.type === 'edit') {
      this.props.history.push({
        pathname: DASHBOARD
      });
    }
  }

  redirectToEditApp = (id) => {
    this.props.history.push({
      pathname: ADDEDITAPP,
      id: id,
      type: 'edit'
    })
  }

  deleteHandler = () => {
    axiosInstance.delete(appConfig.api.development + APPDELETE + encryptData(this.props.appId).toString(),
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.props.history.push(DASHBOARD);
      })
      .catch(function (error) {
        console.log(error);
      })
  };

  handleParentApplication = (event) => {
    if (event.target.value === PARENTAPPLICATIONVALUE) {
      this.setState({
        parentApplicationChanged: false,
        parentId: PARENTAPPLICATIONVALUE,
        categoryError: (this.state.categoryId === null) ? "" : FIELDREQ,
        categoryId: (this.props.type == 'edit') ? this.state.categoryValue : null,
      })

    }
    else {
      this.setState({
        parentApplicationChanged: ((this.state.parentValue === event.target.value) && (this.props.type == 'edit')) ? false : true,
        categoryChanged: true,
        categoryError: "",
        parentId: event.target.value,
        categoryId: null
      });
    }
  };

  saveSelectedCategory = (list) => {
    this.setState({
      categoryId: list.id,
      categoryError: "",
      categoryChanged: true
    })
  };

  isAppDefaultSettings=(event)=>{
    this.setState({isDefaultMapped:event.target.checked,isAppMappedChanged:true})
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper square={true} classes={{ root: classes.editAppPaperSize, elevation1: classes.editGroupPaper }}>
          <div className={classes.editTitle}>
            <p className={classes.editGroupTitle}>{(this.props.type === 'edit') ? EDITAPP : NEWAPP}
              <span className={classes.closeIcon} onClick={(e) => this.onCloseHandler()}>
                <EditClose />
              </span>
            </p>
          </div>
          <div className={(this.props.type === 'edit') ? classes.editAppContent : classes.editAddAppContent}>
            <Typography classes={{ root: classNames(classes.disclaimer, classes.adjustDisclaimerPadding) }}>
              {REQUIREDFIELDDISCLAIMERTEXT}
            </Typography>

            <form className={classes.container} onSubmit={(e) => this.onSubmitHandler(e)} noValidate>
              {
                this.state.fields.map((field, index) => {
                  return <div key={index} className={classes.fieldContainer}>
                    {
                      (index !== 2)
                        ?
                        <>
                          <span className={classes.requiredAsterik}>
                            *
                          </span>
                          <span className={classes.groupName}>
                            <CustomTextField disabled={field.disabled} id={index} classes={classes} label={field.fieldName} name={field.value} nameError={field.error} nameChangeHandler={(e) => this.fieldChangeHandler(e.target.value, index)} validateName={(e) => this.validateField(index)} />
                            <div className={classes.errorText}>{field.error}</div>
                          </span>
                        </>
                        :
                        <span className={classNames(classes.groupName, classes.optionalField)}>
                          <CustomTextField disabled={field.disabled} id={index} classes={classes} label={field.fieldName} name={field.value} nameError={field.error} nameChangeHandler={(e) => this.fieldChangeHandler(e.target.value, index)} validateName={(e) => this.validateField(index)} />
                          <div className={classes.errorText}>{field.error}</div>
                          <Typography classes={{ root: classNames(classes.disclaimer, classes.adjustDisclaimerPadding) }}>
                            {DEVURLDISCLAIMER}
                          </Typography>
                        </span>
                    }
                  </div>
                })
              }
              <div className={classes.uploadFieldContainer}>
                <span className={classes.requiredAsterik}>
                  *  {PARENTAPPLICATION}
                </span>
                <span className={classNames(classes.groupParentSelect, classes.optionalField, classes.parentApplication)}>
                  <Typography classes={{ root: classNames(classes.disclaimer, classes.adjustDisclaimerPadding) }}>
                    {PARENTAPPLICATIONSELECT}
                  </Typography>
                  <Select
                    labelId="demo-simple-select-placeholder-label-label"
                    id="selectParentApplication"
                    value={this.state.parentId}
                    onChange={this.handleParentApplication}
                    className={classes.selectParentName}
                  >
                    <MenuItem value={PARENTAPPLICATIONVALUE}>{SYSTEMTOPLEVEL}</MenuItem>
                    {this.state.appValues.map((app, index) =>
                      <MenuItem key={index} value={app.id}>{app.name}</MenuItem>
                    )}
                  </Select>
                </span>
              </div>
              {
                (this.state.parentId === PARENTAPPLICATIONVALUE) &&
                <div className={classes.uploadFieldContainer}>
                  <span className={classes.requiredAsterik}>
                    *  {CATEGORYCONTROL}
                  </span>
                  <span className={classNames(classes.groupParentSelect, classes.optionalField)}>
                    <CategoryDetails classes={classes} categoryList={this.state.categoryList} categoryId={this.state.categoryId} saveSelectedCategory={this.saveSelectedCategory} />
                    <div className={classes.errorText}>{this.state.categoryError}</div>
                  </span>
                </div>
              }
              <div className={classes.uploadFieldContainer}>
                <pre>
                  <span className={classes.requiredAsterik}>
                    *    {IMAGE}
                  </span>
                </pre>
                <div className={classes.uploadDiv}>
                  <Button variant="contained" color="primary" onClick={this.showUpload} disableRipple classes={{ root: classes.uploadButton, label: classes.addAppButtonLabel }}>{UPLOAD}</Button>
                  <input type='file' id="file" accept="image/jpeg,image/bmp,image/png" onChange={(e) => this.onfileUpload(e)} onClick={(e) => this.clearFileContent(e)} className='hide' />
                  <span className={classes.fileName}>{this.state.fileName}</span>
                </div>
                <div className={classes.imageDisclaimerContainer}>
                  <Typography classes={{ root: classNames(classes.disclaimer, classes.adjustDisclaimerPadding) }}>
                    {IMGUPLOADDISCLAIMER}
                  </Typography>
                  <Typography classes={{ root: classNames(classes.disclaimer, classes.preview) }}>
                    {PREVIEW}
                  </Typography>
                  <Typography classes={{ root: classNames(classes.imageUploadError, classes.errorText) }}>
                    {this.state.imageUploadError}
                  </Typography>
                </div>
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12} zeroMinWidth key={0} classes={{ item: classes.appEditLevelItem }}>
                <Card className={classes.appCard}>
                  <div className="EditAppContainer">
                    <CardHeader
                      classes={{
                        root: classes.cardHeader,
                        title: classes.appTitle,
                      }}
                      action={this.props.type === 'edit' &&
                        <IconButton onClick={() => downloadImage(this.props.appId, this.state.imageType)}>
                          <GetAppIcon color="primary" />
                        </IconButton>
                      }
                      title={this.state.fields[0].value}
                    />
                  </div>
                  <CardContent className={classes.cardContent}>
                    {(this.state.imageURL !== '') ?
                      <CardMedia
                        className={classes.media}
                        component="img"
                        image={(`${this.state.imageURL}` && this.state.imageURL.includes('blob')) ? `${this.state.imageURL}` : getImageUrl(this.props.appId, this.state.imageType)}
                      /> : ''
                    }                    
                  </CardContent>
                  <CardContent style={{ display:'flex', justifyContent:'center' }}>App Image</CardContent>
                </Card>
              </Grid>
              {(this.props.type === 'edit') ?
                <div className={classNames(classes.userButtonRootContainer, classes.userButtonRootContainerMargin)}>
                  <DeletePopUp header={DELETEAPPHEADER} content={DELETEAPPCONTENT} deleteHandler={() => this.deleteHandler()} />
                </div>
                : ''}
              <div className={classes.saveButton}>
                <Button type="submit" variant="contained" disableRipple className={classNames(classes.margin, classes.updatebutton)}>{(this.props.type === 'add' || this.props.location.type === 'add') ? CONTINUETEXT : SAVETEXT}</Button>
              </div>

            </form>
            <ToastMessages statusMessage={this.state.statusMessage} message={this.state.message} variant={this.state.messageVariant} toastHPosition={this.state.toastHPosition} toastVPosition={this.state.toastVPosition} close={this.handleToast.bind(this)} />
          </div>
        </Paper>
        <DialogBox dialogContent={UNSAVEDCHANGESDESCRIPTION} dialogHead={UNSAVEDCHANGESHEADERTEXT} dialogButtonText={CONTINUETEXT} opennow={this.state.opennow} onCloseClick={(e) => this.redirectToDashboard()} onCloseEvent={(e) => this.removeDialogPopup()} />
      </>
    )
  }
}

AppDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AppDetails));