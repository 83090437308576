import axiosInstance from '../Shared/Interceptor/interceptor'
import appConfig from '../../Environment/environments';
import * as constants from '../../Common/Constants/constants';
import { encryptData } from '../../Common/Utilities/utility';

let GroupService = class {


  static getGroupsById(ParentGroupID, callback) {
    axiosInstance.get(`${appConfig.api.development}${constants.GROUPBYID}${encryptData(ParentGroupID).toString()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        callback(response.data);

      });
  }
  static getAllGroups(callback) {
    axiosInstance.get(`${appConfig.api.development}${constants.GROUPALL}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        callback(response.data);

      });
  }
}

export default GroupService;
