export const checkboxSettings = (value,child,appID, parentId,saveSettings,updatedChildSettings) => {
  getCheckboxCount(updatedChildSettings,value,child)
  child.isSelected = value;
  if(value === false){
    child.totalSelectedChildCount = 0
  }
  let levelNo = (value === true) ? 1 : 0;
  toggleCheckboxChildren(child.children, levelNo, appID, parentId, value,saveSettings);
}

export const toggleCheckboxChildren = (children, levelNo, appID, parentId, selected,saveSettings) => {
children.forEach((setting) => {
  setting.levelNo = levelNo;
  if (selected != true && setting.isSelected === true) {
    setting.isSelected = false;
    setting.totalSelectedChildCount = 0
    saveSettings("setting", appID, setting.settingId, setting.isSelected, parentId, false);
  }
  if (setting.children) {
    toggleCheckboxChildren(setting.children, 0, appID, parentId, selected,saveSettings)
  }
  return setting;
});
}

export const toggleChildSettings = (updatedChild, levelNo) => {
if (updatedChild.children) {
  updatedChild.children.forEach(setting => {
    setting.levelNo = levelNo;
    if (setting.children) {
      toggleChildSettings(setting, 0)
    }
    return setting;
  });
}
}

export const checkAllChildren = (updatedChild, isSelected, appID, parentId,saveSettings,updatedChildSettings) => {
  getMultipleCount(updatedChildSettings,isSelected,updatedChild)
  let levelNo = (isSelected === true) ? 1 : 0;
  updatedChild.isSelected = isSelected;
  checkChildren(updatedChild.children, isSelected, levelNo,appID, parentId,saveSettings)
  updatedChild.totalSelectedChildCount = (isSelected === true)  ? updatedChild.totalChildCount : 0;
}

export const checkChildren = (updatedChild, isSelected, levelNo,appID, parentId,saveSettings) =>{
updatedChild.forEach(setting => {
  setting.levelNo = levelNo;
  setting.isSelected = isSelected;
  setting.totalSelectedChildCount = (isSelected === true)  ? setting.totalChildCount : 0;
  saveSettings("setting", appID, setting.settingId, isSelected, parentId, !isSelected);
  if (setting.children) {
    checkChildren(setting.children, isSelected, levelNo,appID, parentId,saveSettings)
  }
  return setting
});
}

export const selectedSettingCount = (updatedChildSettings) => {
let count = 0
updatedChildSettings.forEach((item) => {
  if (item.isSelected === true) {
    count++;
    if (item.totalSelectedChildCount != 0) {
      count += item.totalSelectedChildCount
    }
  }
});
return count;
}

export const getCheckboxCount = (updatedChildSettings,value,child) => {
  let count = 0;
  if (value) {
    if (child.totalChildCount === child.totalSelectedChildCount ) {
      if(child.children.length === 0){
        count = 1
      }
      else{
        count = count-child.totalSelectedChildCount;
      }
    }
    else {
      count = 1
    }
  }
  else {
    count = child.totalSelectedChildCount+1;
  }
  getMultipleSettingsCount(child.settingId, updatedChildSettings, value, child, count)
}

export const getMultipleCount = (updatedChildSettings,value,child) => {
  let count = 0;
  if (value) {
    if (child.totalChildCount === child.totalSelectedChildCount && child.isSelected !== true) {
      count = child.totalChildCount + 1;
    }
    else {
      if (child.totalChildCount !== child.totalSelectedChildCount) {
        if (child.isSelected === true) {
          count = child.totalChildCount - child.totalSelectedChildCount
        }
        else {
          count = child.totalChildCount - child.totalSelectedChildCount + 1
        }
      }
    }
  }
  else {
    if (child.totalChildCount === child.totalSelectedChildCount && child.isSelected !== false) {
      count = child.totalChildCount + 1;
    }
    else if (child.totalSelectedChildCount === 0 && child.isSelected === true) {
      count = 1
    }
    else {
      if (child.totalChildCount !== child.totalSelectedChildCount && child.isSelected === true) {
        count = child.totalSelectedChildCount + 1
      }
    }
  }
  getMultipleSettingsCount(child.settingId, updatedChildSettings, value, child, count)
}
export const getMultipleSettingsCount = (settingId, childArray, selected,child,count) =>{
  if (!childArray) { return }
  for (const parent of childArray) {
    if (parent.settingId === settingId) {
      return [parent.settingId]
    } else {
      let res = getMultipleSettingsCount(settingId, parent.children, selected,child,count)
      if (res) {
        if (selected === true) {
         parent.totalSelectedChildCount = parent.totalSelectedChildCount + count
        }
        else {
          parent.totalSelectedChildCount = parent.totalSelectedChildCount - count 
        }
        res.push(parent)
        return res
      }
    }
  }
  return
}