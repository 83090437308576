import {USERS_ACTIVE_MONTH,USERS_ACTIVE_WEEK,USERS_ACTIVE_HOUR,USERS_ACTIVE_DAY, CHARTTEXT } from '../../../Common/Constants/constants';

const createPieChartData = (userCountStatus) => {
  const { totalUserCount = 0, pendingUserCount = 0, notActiveUserCount = 0,
    authUserCount = 0, adminCount = 0, userCount = 0 } = userCountStatus;

  if (!totalUserCount) {
    return [[{
      "id": CHARTTEXT.noUsers,
      "value": 1
    }], [{
      "id": CHARTTEXT.noUsers,
      "value": 1
    }]]
  }
  else {
    return [[{
      "id": CHARTTEXT.autheticated,
      "value": authUserCount
    },
    {
      "id": CHARTTEXT.notActive,
      "value": notActiveUserCount
    },
    {
      "id": CHARTTEXT.pending,
      "value": pendingUserCount
    }],
    [{
      "id": CHARTTEXT.admins,
      "value": adminCount
    },
    {
      "id": CHARTTEXT.users,
      "value": userCount
    }]]
  }
}

const createUserActiveData = (userCountStatus) => {
  const { totalUserCount = 0, groupActiveCount = 0, groupInActiveCount = 0,
    selGroupActiveCount = 0, selGroupInActiveCount = 0 } = userCountStatus;

  let adminActive = [];
  if (groupActiveCount == 0 && groupInActiveCount == 0) {
    adminActive = [{
      "id": CHARTTEXT.noUsers,
      "value": 1
    }]
  } else {
    adminActive = [{
      "id": CHARTTEXT.autheticated,
      "value": groupActiveCount
    },
    {
      "id": CHARTTEXT.notActive,
      "value": groupInActiveCount
    }]
  }

  let userActive = [];
  if (selGroupActiveCount == 0 && selGroupInActiveCount == 0) {
    userActive = [{
      "id": CHARTTEXT.noUsers,
      "value": 1
    }]
  } else {
    userActive = [{
      "id": CHARTTEXT.autheticated,
      "value": selGroupActiveCount
    },
    {
      "id": CHARTTEXT.notActive,
      "value": selGroupInActiveCount
    }]
  }

  return [adminActive, userActive];
}

const createInsightsData = (insightsInfo) => {
  const { activeUserStatus, userCountStatus, totalChildCount, userCountPerGroups } = insightsInfo;
  const { currentlyActiveUser = 0, lastHourActive = 0, lastDayActive = 0, lastWeekActive = 0, lastMonthActive = 0 } = activeUserStatus;
  const { totalUserCount = 0 } = userCountStatus;
  const { chartGroupNames, groupStatsChartData, groupLoginChartData } = createGroupChartData(userCountPerGroups);
  const pieChartData = createPieChartData(userCountStatus);
  const userActiveData = createUserActiveData(userCountStatus);

  return {
    groupStats: {
      totalGroups: totalChildCount,
      groupStatIndexBy: CHARTTEXT.groupName,
      chartGroupNames: chartGroupNames,
      groupsStatData: groupStatsChartData
    },
    groupLoginStats: {
      totalGroups: totalChildCount,
      groupStatIndexBy: CHARTTEXT.groupName,
      chartGroupNames: chartGroupNames,
      groupsStatData: groupLoginChartData
    },
    userStats: {
      totalUsersActive: currentlyActiveUser,
      data: [
        {
          "id": CHARTTEXT.lastMonth,
          "value": lastMonthActive,
          "label": USERS_ACTIVE_MONTH
        },
        {
          "id": CHARTTEXT.lastWeek,
          "value": lastWeekActive,
          "label": USERS_ACTIVE_WEEK
        },
        {
          "id": CHARTTEXT.lastDay,
          "value": lastDayActive,
          "label": USERS_ACTIVE_DAY
        },
        {
          "id": CHARTTEXT.lastHour,
          "value": lastHourActive,
          "label": USERS_ACTIVE_HOUR
        }
      ]
    },
    authStats: {
      totalUserCount: totalUserCount,
      chartData: pieChartData
    },
    activeStats: {
      totalUserCount: totalUserCount,
      chartData: userActiveData
    }
  }
}

const createGroupChartData = (userCountPerGroups) => {
  let groupStatsChartData = [];
  let groupLoginChartData = [];
  let chartGroupNames = [];
  userCountPerGroups.forEach((group, index) => {
    const { groupName, userCount, totalLogInTime, logInUserCount } = group;
    chartGroupNames.push(groupName);
    let chartData = {};
    let loginChartData = {};
    chartData[CHARTTEXT.groupName] = index;
    chartData[index] = (userCount === 0) ? 0.1 : userCount;
    groupStatsChartData.push(chartData);
    loginChartData[CHARTTEXT.groupName] = index;
    loginChartData[index] = (logInUserCount === 0) ? 0.1 : totalLogInTime / logInUserCount;
    groupLoginChartData.push(loginChartData);
  })
  return {
    chartGroupNames,
    groupStatsChartData,
    groupLoginChartData
  }
}

const initialData = {
  userStats: {},
  authStats: {},
  activeStats: {},
  groupStats: {},
  groupLoginStats: {},
  loading: true
}

const init = () => {
  return initialData;
}

const insightsReducer = (state, action) => {
  switch (action.type) {
    case 'updateUserStats':
      const {userStats,authStats,groupStats, groupLoginStats, activeStats } = action.payload;
      return {
        ...state,
        userStats,
        authStats,
        groupStats,
        groupLoginStats,
        activeStats,
        loading: false,
      };
    case 'reset':
      return init();
    default:
      return state;
  }
}

export { init, initialData, createInsightsData, insightsReducer };