import { WHITELIST, BLACKLIST, WHITELIST_PARA_1, WHITELIST_PARA_3, WHITELIST_PARA_4 } from '../../../../Common/Constants/constants';

const DomainData = [
    {
        "id": "whitelist",
        "label": WHITELIST,
        "content": [],
        "helpContents": [
            WHITELIST_PARA_1
        ],
        "enableButton": false,
        "textFieldValue": "",
        "errorMessage": ""
    },
    {
        "id": "blacklist",
        "label": BLACKLIST,
        "content": [],
        "helpContents": [
            WHITELIST_PARA_4,
            WHITELIST_PARA_3
        ],
        "enableButton": false,
        "textFieldValue": "",
        "errorMessage": ""
    }
];

export {DomainData};