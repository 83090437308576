import { makeStyles } from '@material-ui/core/styles';

const userStatsFilterStyles = makeStyles((theme) => ({
    userStatsButtonContainer: {
        display: "flex",
        alignItems: "center",
        margin: '0px 5px 5px 0px'
    },
    userStatsRadioLabel: {
        lineHeight: "35px",
        paddingLeft: "0px",
        color: "black",
        fontSize: "12px",
        paddingTop: "9px"
    },
    userStatsRadioContainer: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        backgroundColor: "white"
    },
    userStatsRadio: {
        border: "1px solid #f7f7f7"
    },
    filterContainer: {
        display:"flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
    },
    filterContent: {
        display:"flex",
        flexDirection: "column",
        marginRight: "3px"
    },
    userStatsContainer: {
        backgroundColor: "#e8e8e8",
        padding: "10px 0px 10px 34px",
        display:"flex",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "17px"
        },
    },
    userStatsReload: {
        margin: "4px",
        position: "absolute",
        right: "20px",
        [theme.breakpoints.down('sm')]: {
            position: 'unset',
            right: 0,
            width: "100%",
            "& button": {
                width: "83vw"
            }
        },
    },
    userStatsFilterContainer: {
        display: "flex",
        flexDirection: "column"
    },
    userStatsGroupName: {
        fontSize: "16px",
    },
    filterHeader:{
        margin: "5px 0px"
    }
}), { withTheme: true });

export default userStatsFilterStyles;