import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemText from "@material-ui/core/ListItemText";
import permissionStyle from './permissionStyle';
import PermissionTreeAction from './permissionTreeActions';
import SettingsIcon from '@material-ui/icons/Settings';

const PermissionTree = (props) => {
  const {appId, getPermissions, depthStep = 10, depth = 0, tree} = props;
  const{children, settingName} = tree;
  const [isOpen, setIsopen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { permissionDiv, nested, listItemBackground, permissionName } = permissionStyle();

  const handleListClick = () => {
    setIsopen((prev) => !prev);
  }

  return (
    <>
      <ListItem className={listItemBackground} button dense {...tree}>
        {children.length > 0 ? <div className={permissionDiv} onClick={handleListClick}>
          {(isOpen ?
            <ExpandLessIcon color="primary" />
            : <ExpandMoreIcon color="primary" />
          )}
        </div>
          : <SettingsIcon color="primary" />
        }
        <ListItemText >
          <span className={permissionName}>
            {settingName}
          </span>
        </ListItemText>
        <PermissionTreeAction  getPermissions={getPermissions} handleListClick ={handleListClick} treeExpandStatus={isOpen}
                  appId={appId} permission = {tree}/>
      </ListItem>
      {
        children.length > 0 ? (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List disablePadding dense className={nested}>
              {children.map((subItem) => (
                <PermissionTree
                  key={subItem.settingId}
                  depth={depth + 1}
                  depthStep={depthStep}
                  tree={subItem}
                  getPermissions={getPermissions}
                  appId={appId}
                />
              ))}
            </List>
          </Collapse>
        ) : null}
    </>
  )
}

export default PermissionTree;