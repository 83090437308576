import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../Reducers/rootReducer';

const middleware=[thunk];

// In development, use the browser's Redux dev tools extension if installed
const enhancers = [];
const isDevelopment = process.env.NODE_ENV === "development";
if (
    isDevelopment &&
    typeof window !== "undefined" &&
    window.devToolsExtension
) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : f => f);
}

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(...middleware), 
        ...enhancers
    )    
);

export default store;