import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel, Switch, InputAdornment, OutlinedInput } from '@material-ui/core';
import ManageUsers from '../manageUsers/manageUsers';
import DialogBox from "../../Shared/OptionsMenu/dialog";
import { ReactComponent as EditClose } from '../../../Assests/Images/EditGroupClose.svg';
import ReactTooltip from 'react-tooltip';
import Info from '../../../Assests/Images/Info.svg';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import DeletePopUp from '../../Shared/OptionsMenu/DeletePopUp';
import appConfig from '../../../Environment/environments';
import ToastMessages from '../../Shared/Toast/ToastMessages';
import RightDrawer from '../../Shared/RightDrawer/RightDrawer';
import ChangeGroup from '../changeGroup/changeGroup';
import WarningIcon from '@material-ui/icons/Warning';
import {
  TimeoutStyles, EditGroupStyles, PARENTGROUPCHANGE, GROUPEDIT, CHANGEPARENTGROUP, APPLYALLFAIL, APPLYALLSUCCESS, GROUPS, GROUPAPI, GROUPDEL, DELETEGRPDIALOG, DELETEGROUP, FIELDREQ, GROUPLENGTH, GROUPROLES, GROUPROLESERROR, SETTINGSLABEL, ROLESTEXT, SAVETEXT,
  REQUIREDFIELDDISCLAIMERTEXT, PARENTGROUP, EDITGROUPTEXT, UNSAVEDCHANGESHEADERTEXT, UNSAVEDCHANGESDESCRIPTION, CONTINUETEXT, SETTINGSSAVETEXT, SETTINGSERRORTEXT, USERALREADYEXISTSERROR, EDITGROUP,
  TIMEOUTLABEL, ON, OFF, DEACTIVATEGROUPMESSAGE, NEXTTIMEOUTLABEL, MINVALUE, MAXVALUE, WEEKS, GROUPDISABLEINFO, GROUPDISABLE, GROUPEXPIRYINFO, NUMBERSREGEX,
  GROUPDISABLEMSG, PARENTGROUPDISABLEMSG } from '../../../Common/Constants/constants';
import { getLoggedInUserDetails, getGroupDetailsByID } from './editGroupHelper';
import SettingsDrawer from '../../Shared/Settings/SettingsDrawer';
import ApplyAllGroupsDrawer from '../../Shared/ApplyAllGroups/ApplyAllGroupsDrawer';
import CustomTextField from '../CustomTextField';
import { encryptData, injectIntlTranslation } from '../../../Common/Utilities/utility';
import { intlShape, injectIntl } from "react-intl";
import {getUserType} from '../../../Common/Utilities/utility';

const IOSSwitch = withStyles(theme => TimeoutStyles)(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
class EditGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labelWidth: 0,
      groupName: '',
      selectedGroupName: '',
      parentGroup: '',
      parentGroupId: 0,
      anchorEl: null,
      errorState: false,
      name: '',
      nameError: '',
      statusMessage: false,
      message: '',
      toastVPosition: 'top',
      toastHPosition: 'center',
      messageVariant: '',
      changed: false,
      loggedInUserType: '',
      settingsLabel: SETTINGSLABEL,
      openSettingsDialog: false,
      openApplyAllDialog: false,
      savedAppSettings: {},
      isManager: false,
      isEditable: false,
      opennow: false,
      groupId: this.props.id,
      expandedGroups: this.props.expandedGroups || [],
      right: false,
      parentChanged: false.target,
      selectedTimeout: '',
      isTimeout: '',
      selectedTimeoutFreq: 0,
      timeoutFreq: 0,
      nextTimeoutDate: '',      
      isParentActive : true,
      isDisable: false,
      selectDisable: true,
      isDisableChange: false,
      isDisableEdit: false,
      loggedInUserName:''

    }
    this.handleTimeout = this.handleTimeout.bind(this);
    this.handleDisable = this.handleDisable.bind(this);
  }

  componentDidMount() {
    getLoggedInUserDetails(this.getLoggedInUserDetailsSuccessCallback);
    getGroupDetailsByID(this.state.groupId, this.getGroupDetailsByIDSuccessCalback);
  }

  getTranslatedString = (constantName) => {
    return injectIntlTranslation(this.props.intl, constantName)
  }

  getLoggedInUserDetailsSuccessCallback = (isManager,firstname,lastname) =>{
  this.setState({ 
    loggedInUserType: getUserType(), isManager: isManager,
    loggedInUserName:firstname +' '+lastname
   })

  }
  

  toggleDrawer = (side, open, e) => {
    if (e.type !== 'keydown' && (e.key !== 'Tab' || e.key !== 'Shift')) {
      this.setState({
        [side]: open
      })
    }
    return;
  };

  getGroupDetailsByIDSuccessCalback = (label, parentGroupName, parentGroupID, isEditable, isTimeout, timeoutFreq, nextTimeoutDate, isActive, isParentDisable, isDisableEditable) => {
    this.setState({
      groupName: (this.state.groupName === "") ? label : this.state.groupName,
      parentGroup: parentGroupName,
      selectedGroupName: label,
      parentGroupId: parentGroupID,
      isEditable: isEditable,
      selectedTimeout: isTimeout,
      isTimeout: (this.state.isTimeout === "") ? isTimeout : false,
      selectedTimeoutFreq: (timeoutFreq === 0) ? 12 : timeoutFreq,
      timeoutFreq: (timeoutFreq === 0) ? 12 : timeoutFreq,
      nextTimeoutDate: (this.state.nextTimeoutDate === "") ? new Date(nextTimeoutDate).toLocaleDateString(navigator.language) : '',
      isDisable : (typeof(isActive) !== 'undefined' && isActive != null) ? !isActive : false,
      selectDisable : !isActive,
      isParentActive : isParentDisable,
      isDisableEdit : isDisableEditable
    });    
  }

  onCloseHandler = () =>
    (!this.state.changed) ? this.props.onClose(0, false, this.state.parentChanged) : this.setState({ opennow: true });

  removeDialogPopup = () => this.setState({ opennow: false })

  onSubmitHandler = (e) => {
    e.preventDefault();
    const obj = {
      value: this.state.groupId,
      label: this.state.groupName,
      isTimeout: this.state.isTimeout,
      isActive: !this.state.isDisable
    };

    if (obj.label != "") {
      const updatedObj = JSON.stringify(obj);
      axiosInstance.put(appConfig.api.development + GROUPAPI, updatedObj, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }).then(() => {
          this.setState({
            message: GROUPEDIT,
            statusMessage: true,
            messageVariant: "success",
            changed: false,
            parentChanged: true,
            selectDisable: this.state.isDisable,
            selectedTimeout: obj.isTimeout,
            isDisableChange : false,
            change: false
          });
      }).catch((error) => {
        this.setState({
          statusMessage: true,
          message: USERALREADYEXISTSERROR,
          messageVariant: "error"
        });
      });
    }
  }

  settingsSaveStatus = (result, isChildAvailable, savedAppSettings) => {
    if (result) {
      this.setState({
        message: SETTINGSSAVETEXT,
        statusMessage: true,
        messageVariant: "success",
        openSettingsDialog: false,
        openApplyAllDialog: isChildAvailable,
        savedAppSettings: savedAppSettings
      })
    }
    else {
      this.setState({
        statusMessage: true,
        message: SETTINGSERRORTEXT,
        messageVariant: "error",
        openSettingsDialog: true,
        openApplyAllDialog: isChildAvailable,
        savedAppSettings: []
      });
    }
  }

  applyAllSave = (result) => {
    if (result) {
      this.setState({
        message: APPLYALLSUCCESS,
        statusMessage: true,
        messageVariant: "success",
        openApplyAllDialog: false
      })
    }
    else {
      this.setState({
        statusMessage: true,
        message: APPLYALLFAIL,
        messageVariant: "error",
        openApplyAllDialog: false
      });
    }
  }

  handleToast = (event, reason) => {
    if (reason !== 'clickaway') {
      this.setState({
        statusMessage: false
      });
    }
    return;
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  groupNameChangeHandler = groupName => event => {
    const name = event.target.value.trimStart();
    this.setState({ [groupName]: name, changed: (event.target.value === this.state.selectedGroupName) ? false : true }, () => {
      this.validateName();
    });
  };

  validateName = () => {
    const { groupName } = this.state;
    this.setState({
      nameError:
        ((groupName == "") ? FIELDREQ : (groupName.length > 100 ? GROUPLENGTH : null))
    });
  }

  openInviteUserDrawer = (side = "right", open = true) => event => {
    this.setState({ openSettingsDialog: open, });
  };

  openApplyAllDrawer = (side = "right", open = true) => event => {
    this.setState({ openApplyAllDialog: open, statusMessage: false });
  };

  onParentChange = (changedParentId) => {
    this.setState({
      groupId: changedParentId,
      parentChanged: true,
      right: false,
      message: PARENTGROUPCHANGE,
      statusMessage: true,
      messageVariant: "success"
    }, () => {
      getGroupDetailsByID(this.state.groupId, this.getGroupDetailsByIDSuccessCalback);
    })
  }

  onContentChange = () => this.setState({ parentChanged: true })

  deleteGroupHandler = () => {
    const intl = this.props.intl;
    axiosInstance.delete(appConfig.api.development + GROUPAPI + "/?GroupId=" + encryptData(this.state.groupId).toString(),
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        this.props.deleteGroupHandler(true, injectIntlTranslation(intl, GROUPDEL), "success");
      })
      .catch(function (error) {
        console.log(error);
      })
  };

  handleTimeout = (event) => {
    if (event.target.checked) {
      this.setState({ isTimeout: true, timeoutFreq: this.state.selectedTimeoutFreq, changed: (event.target.checked === this.state.selectedTimeout) ? false : true })
    }
    else {
      this.setState({ isTimeout: false, timeoutFreq: 0, changed: (event.target.checked === this.state.selectedTimeout) ? false : true })
    }
  }
  
  handleDisable = (event) => {
    if (event.target.checked)
      this.setState({ isDisable: true, isDisableChange: (event.target.checked === this.state.selectDisable) ? false : true })
    else 
      this.setState({ isDisable: false, isDisableChange: (event.target.checked === this.state.selectDisable) ? false : true })
    }

  setGroupTimeOut = (event) => {
    if ((event.target.value) !== this.state.selectedTimeoutFreq) {
      if ((NUMBERSREGEX.test(event.target.value))) {
        this.setState({ timeoutFreq: event.target.value, changed: true });
      }
    }
    else {
      this.setState({ timeoutFreq: event.target.value, changed: false });
    }
  }
  setTimeOutActivity = (event) => {
    if ((event.target.value) === this.state.selectedTimeoutFreq) {
      this.setState({ changed: false });
    }
  }

  setParentEditGroup =(value)=>{
    this.props.onClose(0, value, true)
  }

  render() {
    const { classes, intl } = this.props;
    const { usersPaper, margin, updatebutton, saveButton, userButtonRoot, userButtonRootContainer, parentChangeButton, parentGroupSpan, parentGroup, parentGroupContainer, root, errorText, groupName, requiredAsterik, editContent, container, disclaimer, gridContainer, appItem, editPaperSize, editGroupPaper, closeIcon, editTitle, editGroupTitle, disableControl, userButtonRootContainerDisable, parentChangeButtonDisable } = classes;
    return (
      <div className={root}>
        <div>
          <Grid container spacing={3} classes={{ container: gridContainer }}>
            <Grid item xs={12} sm={12} md={3} classes={{ item: appItem }}>
              <Paper square={true} classes={{ root: editPaperSize, elevation1: editGroupPaper }}>
                <div className={editTitle}>
                  <p className={editGroupTitle}>{EDITGROUPTEXT}
                    <span className={closeIcon} onClick={this.onCloseHandler}>
                      <EditClose />
                    </span>
                  </p>
                </div>
                <div className={editContent}>
                  <Typography classes={{ root: disclaimer }}>
                    {REQUIREDFIELDDISCLAIMERTEXT}
                  </Typography>
                  <form className={container} onSubmit={this.onSubmitHandler} noValidate>
                    <span className={requiredAsterik}>
                      *
                    </span>
                    <div className={groupName}>
                      <CustomTextField disabled={(!this.state.selectDisable)? false : true} classes={classes} name={this.state.groupName} nameError={this.state.nameError} 
                        nameChangeHandler={this.groupNameChangeHandler('groupName')} validateName={this.validateName} />
                      <div className={errorText}>{this.state.nameError}</div>
                    </div>
                    <div className={parentGroupContainer}>
                      {PARENTGROUP}
                      <div className={parentGroup}>
                        <span className={parentGroupSpan}>{this.state.parentGroup}</span>
                        {this.state.parentGroupId != 0 &&
                          <div className={(!this.state.selectDisable)? parentChangeButton : parentChangeButtonDisable}>
                            <input type="button" value={injectIntlTranslation(intl, CHANGEPARENTGROUP)} 
                            className={`changeButton ${!this.state.selectDisable ? '' : 'disableButton'}`} onClick={(e) => (!this.state.selectDisable)? this.toggleDrawer('right', true, e) : null }></input>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="timeoutGroup">
                      <span>
                        <span data-tip={this.getTranslatedString(GROUPEXPIRYINFO)} className="HeaderIconColumn_edit">
                          <img className="GroupsTableInfoIcon" src={Info} xs={2} />
                        </span>
                        <ReactTooltip place="top" type="dark" effect="float" multiline="true" />
                      </span>
                      <span>
                        <span>{TIMEOUTLABEL} : </span>
                        <span className="groupTimeout">
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                disabled={!this.state.selectDisable ? false : true}
                                checked={this.state.isTimeout}
                                onChange={(e) => this.handleTimeout(e)} name="isTimeout" value="true"
                              />
                            }
                            label={this.state.isTimeout ? ON : OFF}
                          />
                        </span>
                      </span>
                    </div>
                    {(this.state.isTimeout === true) &&
                      <span className="timeoutGroupDate">
                        {DEACTIVATEGROUPMESSAGE} <b> {this.state.nextTimeoutDate} </b>
                      </span>
                    }
                    <div className="timeoutGroup">
                      <span>
                        <span data-tip={this.getTranslatedString(GROUPDISABLEINFO)} className="HeaderIconColumn_edit">
                          <img className="GroupsTableInfoIcon" src={Info} xs={2} />
                        </span>
                        <ReactTooltip place="top" type="dark" effect="float" multiline="true" />
                      </span>
                      <span>
                        <span>{GROUPDISABLE} : </span>
                        <span className="groupTimeout">
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                disabled={( (this.state.isParentActive && this.state.isEditable && this.state.isDisableEdit) || (this.state.parentGroupId == 0))? false : true}
                                checked={this.state.isDisable}
                                onChange={(e) => this.handleDisable(e)} name="isDisable" value="true"
                              />
                            }
                            label={this.state.isDisable ? ON : OFF}
                          />
                        </span>
                      </span>                      
                    </div>
                    {this.state.parentGroup != '' && this.state.selectDisable &&
                        <span className="groupDisableMsg">
                          <span style={{verticalAlign:'middle'}}><WarningIcon></WarningIcon></span>                          
                          <span>{GROUPDISABLEMSG}</span>                   
                        </span>
                    }
                    <div className={(!this.state.selectDisable)? userButtonRootContainer: userButtonRootContainerDisable} >
                      <Button variant="contained" disableRipple className={(!this.state.selectDisable)? userButtonRootContainer: userButtonRootContainerDisable} classes={{ root: userButtonRoot }} onClick={ (!this.state.selectDisable)? this.openInviteUserDrawer('right', true) : null} >
                        {this.state.settingsLabel}
                      </Button>
                      <DeletePopUp header={DELETEGROUP} content={DELETEGRPDIALOG} isDisable={this.state.selectDisable} deleteHandler={() => this.deleteGroupHandler()} />
                    </div>
                    {this.state.openSettingsDialog && <SettingsDrawer open={true} groupLevel={EDITGROUP} groupId={this.state.groupId} onSave={this.settingsSaveStatus} isEditable={this.state.isEditable} onClose={this.openInviteUserDrawer('right', false)} />}
                    <ApplyAllGroupsDrawer open={this.state.openApplyAllDialog} groupId={this.state.groupId} savedAppSettings={this.state.savedAppSettings} onSave={this.applyAllSave} onClose={this.openApplyAllDrawer('right', false)} type="group" />
                    {(this.state.changed || this.state.isDisableChange)&&
                      <div className={saveButton}>
                        <Button type="submit" variant="contained" disableRipple className={classNames(margin, updatebutton)}>{SAVETEXT}</Button>
                      </div>
                    }
                  </form>
                  <ToastMessages statusMessage={this.state.statusMessage} message={this.state.message} variant={this.state.messageVariant} toastHPosition={this.state.toastHPosition} toastVPosition={this.state.toastVPosition} close={this.handleToast.bind(this)} />
                  <RightDrawer customProps={{ width: 600 }} open={this.state.right}>
                    <ChangeGroup callApiOnSave onParentChange={this.onParentChange} GroupId={this.state.groupId} PrevParentGroupId={this.state.parentGroupId} onClose={(e) => this.toggleDrawer('right', false, e)} />
                  </RightDrawer>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={9} classes={{ item: appItem }}>
              <Paper square={true} classes={{ root: usersPaper, elevation2: editGroupPaper }}>
                <ManageUsers onContentChange={this.onContentChange} historyid={this.props.history} Id={this.state.groupId}
                  groupName={this.state.groupName} loggedInUserType={this.state.loggedInUserType} loggedInUserName={this.state.loggedInUserName}
                  isTimeout={this.state.isTimeout} nextTimeout={this.state.nextTimeoutDate} isDisable={this.state.selectDisable} setParentEditGroup={this.setParentEditGroup} />
              </Paper>
            </Grid>
          </Grid>
          <DialogBox dialogContent={UNSAVEDCHANGESDESCRIPTION} dialogHead={UNSAVEDCHANGESHEADERTEXT} dialogButtonText={CONTINUETEXT} opennow={this.state.opennow} onCloseClick={() => this.props.onClose(0, false, this.state.parentChanged)} onCloseEvent={this.removeDialogPopup} />
        </div>
      </div>
    )
  }
}

EditGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(EditGroupStyles)(EditGroup));