import React, {memo,useState,useEffect} from "react";
import {CustomButton} from "../../CustomButton/CustomButton";
import {CustomFilterButton} from "../../CustomFilterButton/customFilterButton";
import userStatsFilterStyles from "./UserStatsFilterStyles";
import {PERMISSIONSTABTEXT,REFRESH,ALL_PERMISSIONS,FILTER_GROUPS,FILTER_PERMISSIONS,
    FILTER_SELECTED_GROUP,FILTER_SELECTED_GROUP_CHILD,DEFAULTVALUE} from '../../../../Common/Constants/constants';
import UserStatsGroupFilter from "./UserStatsGroupFilter";
import SettingsDrawer from '../../Settings/SettingsDrawer';
import { injectIntlTranslation } from '../../../../Common/Utilities/utility';
import { intlShape, injectIntl } from "react-intl";

const UserStatsFilter = (props) => {
    const {groupName,groupId,isButtonDisabled,onReloadButtonClick,intl} = props;
    const [userStatsState, setUserStatsState] = useState({
        selectedFilterType: 0,
        showFilter: false,
        groupSelectionLabels: [FILTER_SELECTED_GROUP,FILTER_SELECTED_GROUP_CHILD],
        groupSelectionType: 1,
        statsGroupName: groupName,
        statsGroupId: groupId,
        totalPermissionsCount: 0,
        permissionsData: [],
        selectedAppPermissions: [],
        filtersContent: [
            {
                filterType: 0,
                filterHeading: FILTER_GROUPS,
                filterLabel: FILTER_SELECTED_GROUP_CHILD,
                isEditMode: false
            },
            {
                filterType: 1,
                filterHeading: FILTER_PERMISSIONS,
                filterLabel: ALL_PERMISSIONS,
                isEditMode: false
            }
        ]
    })
    const {filtersContent,selectedFilterType,showFilter,statsGroupId,selectedAppPermissions,
        groupSelectionLabels,groupSelectionType,statsGroupName,permissionsData} = userStatsState;
    const {userStatsFilterContainer,userStatsContainer,userStatsGroupName,filterHeader,
        userStatsReload,filterContainer,filterContent,userStatsButtonContainer} = userStatsFilterStyles();
    
    useEffect(() => {
        setUserStatsState({...userStatsState, statsGroupName:groupName, statsGroupId:groupId})
    }, [groupName,groupId])

    const reloadHandler = () => onReloadButtonClick && onReloadButtonClick(statsGroupId,permissionsData,groupSelectionType);
    
    const toggleDrawer = (filterType, showFilter) => setUserStatsState({...userStatsState, selectedFilterType: filterType, showFilter: showFilter})

    const saveGroupsData = (groupSelectionData) => {
        const newFilterContent = [...filtersContent];
        newFilterContent[0].filterLabel = groupSelectionLabels[groupSelectionType];
        const {value,label} = groupSelectionData;
        setUserStatsState({
            ...userStatsState,
            filtersContent:newFilterContent,
            showFilter: false,
            statsGroupId: value,
            statsGroupName: label
        })
        onReloadButtonClick(value,permissionsData,groupSelectionType);
    }

    const permissionsSave = (newPermissionsData=[], deletedPermissionsData=[], selectedAppPermissions=[]) => {
        let totalPermissionsSelected = 0;
        for (let key in newPermissionsData) {
            totalPermissionsSelected++;
            const settingsChildren = newPermissionsData[key];
            if (settingsChildren.Settings && settingsChildren.Settings.length > 0) {
                totalPermissionsSelected += settingsChildren.Settings.length;
            }
        }
        const newFilterContent = [...filtersContent];
        if(totalPermissionsSelected > 0) {
            newFilterContent[1].filterLabel = `${totalPermissionsSelected} ${injectIntlTranslation(intl, PERMISSIONSTABTEXT)}`;
            newFilterContent[1].isEditMode = true;
        }
        else {
            newFilterContent[1].filterLabel = ALL_PERMISSIONS;
            newFilterContent[1].isEditMode = false;
        }
        setUserStatsState({
            ...userStatsState,
            filtersContent: newFilterContent,
            permissionsData:newPermissionsData,
            totalPermissionsCount: totalPermissionsSelected,
            selectedAppPermissions: selectedAppPermissions,
            showFilter: false
        })
        onReloadButtonClick(statsGroupId,newPermissionsData,groupSelectionType);
    }

    const clearPermissionsFilter = () => {
        const newFilterContent = [...filtersContent];
        newFilterContent[1].filterLabel = ALL_PERMISSIONS;
        newFilterContent[1].isEditMode = false;
        setUserStatsState({
            ...userStatsState,
            filtersContent: newFilterContent,
            selectedAppPermissions: [],
            permissionsData:[],
            totalPermissionsCount: 0
        })
        onReloadButtonClick(statsGroupId,[],groupSelectionType);
    }

    const hideFilter = () => setUserStatsState({...userStatsState, showFilter: false})

    const changeGroupTypeSelection = (type) => setUserStatsState({...userStatsState, groupSelectionType: type})

    const showFliterDrawer = () => {
        switch (selectedFilterType) {
            case 0:
                return  <UserStatsGroupFilter
                            selectedGroupId={statsGroupId}
                            selectedGroupName={statsGroupName}
                            saveGroupsData={saveGroupsData}
                            groupSelectionType={groupSelectionType}
                            changeGroupTypeSelection={changeGroupTypeSelection}
                            hideFilter={hideFilter}
                        />
                break;
            case 1:
                return <SettingsDrawer
                            open={true}
                            headerLabel={FILTER_PERMISSIONS}
                            groupLevel = {DEFAULTVALUE}
                            groupId={statsGroupId}
                            callSaveApi={false}
                            unSelectByDefault
                            selectedPermissions={selectedAppPermissions}
                            onPermissionsSave={permissionsSave}
                            isEditable={true}
                            onClose={hideFilter} />
                break;
            default: return null;
                break
        }
    }
    return (
        <div className={userStatsContainer}>
            <div className={userStatsFilterContainer}>
                <span className={userStatsGroupName}>{statsGroupName}</span>
                <div className={userStatsReload}>
                    <CustomButton isButtonDisabled={isButtonDisabled} onClick={reloadHandler} buttonLabel={REFRESH}/>
                </div>
                <div className={filterContainer}>
                    {!!filtersContent.length && filtersContent.map((filterItem, index) => {
                        const { filterHeading,filterLabel,filterType,isEditMode } = filterItem;
                        const customFilterProps = {};
                        if (filterType === 1) {
                            customFilterProps.onDelete = clearPermissionsFilter;
                        }
                        return (
                            <div id={index} className={filterContent}>
                                <span className={filterHeader}>{filterHeading}</span>
                                <div className={userStatsButtonContainer}>
                                    <CustomFilterButton 
                                        isButtonDisabled={isButtonDisabled}
                                        onClick={() => toggleDrawer(filterType, true)}
                                        buttonLabel={filterLabel}
                                        isEditMode={isEditMode}
                                        onEdit={() => toggleDrawer(filterType, true)}
                                        {...customFilterProps}
                                    />
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                {showFilter && showFliterDrawer()}
            </div>
        </div>
    )
}

UserStatsFilter.propTypes = {
    intl: intlShape.isRequired
};

export default injectIntl(memo(UserStatsFilter));