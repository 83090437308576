import { makeStyles } from '@material-ui/core/styles';
const customButtonStyles = makeStyles(() => ({
    customButtonRoot: {
      opacity: '1',
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: '1rem',
      color: '#fff',
      lineHeight: '18px',
      backgroundColor: '#0033ab',
      borderRadius: '3px',
      minWidth: 72,
      maxWidth: "100%",
      minHeight: '0',
      height: '35px',
      margin: '0px !important',
      fontWeight: '400',
  
      '&:hover': {
        borderRadius: '3px',
        fontWeight: '400',
        textDecoration: 'underline',
        boxShadow: 'none',
        backgroundColor: '#0033ab',
        color: '#ffffff'
      },
  
      '&:focus': {
        borderRadius: '3px',
        fontWeight: '400',
        textDecoration: 'underline',
        boxShadow: 'none',
        backgroundColor: '#0033ab',
        color: '#ffffff'
      },
  
      '&:active': {
        color: '#fff',
        backgroundColor: '#0033ab',
        borderColor: '#0033ab',
        borderRadius: '3px',
        fontWeight: '400',
      },
    }
}));

export default customButtonStyles;