import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ManageGroupStyles, APPSGROUPSAVESUCCESS, APPSGROUPSAVEFAIL} from '../../../Common/Constants/constants';
import '../../../Assests/Styles/Styles.scss';
import ToastMessages from '../../Shared/Toast/ToastMessages';
import '../../Groups/manageUsers/manageUsers.scss';
import ApplyAllGroupsDrawer from '../../Shared/ApplyAllGroups/ApplyAllGroupsDrawer';

const styles = ManageGroupStyles;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: "0 24px" }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


class GroupsTab extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      statusMessage: false,
      message: "",
      toastVPosition: 'top',
      toastHPosition: 'center',
      messageVariant: '',
    };
  }

  handleToast(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      statusMessage: false
    });
  }

  saveGroupsResponse = (type) => {
    if(type) {
      this.setState({
        statusMessage: true,
        message: APPSGROUPSAVESUCCESS,
        messageVariant: 'success'
      });
    }
    else {
      this.setState({
        statusMessage: true,
        message: APPSGROUPSAVEFAIL,
        messageVariant: 'error'
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
       <div className="ManageUsersHeader">
        <ToastMessages
          statusMessage={this.state.statusMessage}
          message={this.state.message}
          variant={this.state.messageVariant}
          toastHPosition={this.state.toastHPosition}
          toastVPosition={this.state.toastVPosition}
          close={this.handleToast.bind(this)}
        />
      </div>
      <div className="GroupsHeader">
          <ApplyAllGroupsDrawer type="app" Id={this.props.Id} onSave={this.saveGroupsResponse}/>
      </div>
      </div>
    );
  }
}


GroupsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(GroupsTab);
