import { makeStyles } from '@material-ui/core/styles';
const customFilterButtonStyles = makeStyles(() => ({
  filterButton: {
    opacity: '1',
    border: props => (props.isEditMode ? '1px solid #009954' : '1px solid #0033ab'),
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    color: props => (props.isEditMode ? "white" : "#0033ab"),
    lineHeight: '18px',
    backgroundColor: props => (props.isEditMode ? "#009954" : 'transparent'),
    borderRadius: props => (props.isEditMode ? '3px 0px 0px 3px' : '3px'),
    minHeight: '32px',
    maxHeight: '64px',
    padding: '0px 9px',
    fontWeight: '400',
    minWidth: "42px",

    '&:hover': {
      backgroundColor: props => (props.isEditMode ? "#009954" : '#0033ab'),
      color: 'white'
    },

    '&:focus': {
      backgroundColor: props => (props.isEditMode ? "#009954" : '#0033ab'),
      color: 'white'
    },

    '&:active': {
      color: props => (props.isEditMode ? "#009954" : '#FFFFFF'),
      backgroundColor: 'transparent',
      borderColor: props => (props.isEditMode ? "#009954" : '#0033ab')
    },
  },
  filterEdit: {
    color: "#009954 !important",
    backgroundColor: "transparent !important",
    borderRadius: '0px 3px 3px 0px !important',

    '&:hover': {
      backgroundColor: "transparent !important",
    },

    '&:focus': {
      backgroundColor: "transparent !important",
    },

    '&:active': {
      color: "#009954 !important",
    },
  }
}));

export default customFilterButtonStyles;