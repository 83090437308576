import React, {memo} from "react";
import Avatar from '@material-ui/core/Avatar';
import userAuthStyles from "../UserAuthStyles";
import {USERS_LABEL, ADMIN_LABEL } from '../../../../Common/Constants/constants';
import clsx from 'clsx';
import PieChart from '../PieChart'
import { strings } from "../InsightsHelper";

const UserAuthPie = ({authStats}) => {
    const {chartData, totalUserCount} = authStats;
    const classes = userAuthStyles();
    const {avatarFallback,userCount,individualUsersAvatar,individualUsersInfoDiv,individualUsersInfoContainer,pieRoot,totalUsersh,totalUsersDiv,userAuthPie,usersInfoContainer} = classes;
    return (
        <div className={pieRoot}>
            {!!chartData && !!chartData[0] && <>
                <div id="userAuthPie" className={userAuthPie}>
                    <PieChart dataPoints={chartData[0]} />
                </div>
                <div className={usersInfoContainer}>
                    <div className={totalUsersDiv}>
                        <h1 className={totalUsersh}>{totalUserCount} {USERS_LABEL}</h1>
                    </div>
                    <div className={individualUsersInfoContainer}>
                        {chartData[0].map(dataItem => {
                            const { id, value } = dataItem;
                            return (
                                <div id={id} className={individualUsersInfoDiv}>
                                    <Avatar classes={{ fallback: avatarFallback }} className={clsx(classes[id], individualUsersAvatar)} />
                                    {!!totalUserCount && <span className={userCount}>{value}</span>}
                                    {strings[id]}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
            }

            {!!chartData[1] && <>
                <div id="userAuthPie" className={userAuthPie}>
                    <PieChart dataPoints={chartData[1]} />
                </div>
                <div className={usersInfoContainer}>
                    <div className={totalUsersDiv}>
                        <h1 className={totalUsersh}>{ADMIN_LABEL}/{USERS_LABEL}</h1>
                    </div>
                    <div className={individualUsersInfoContainer}>
                        {chartData[1].map(dataItem => {
                            const { id, value } = dataItem;
                            return (
                                <div id={id} className={individualUsersInfoDiv}>
                                    <Avatar classes={{ fallback: avatarFallback }} className={clsx(classes[id], individualUsersAvatar)} />
                                    {!!totalUserCount && <span className={userCount}>{value}</span>}
                                    {strings[id]}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
            }
        </div>
    )
}

export default memo(UserAuthPie);