import React, { memo } from "react";
import { ResponsiveBar } from '@nivo/bar';
import userAuthStyles from "../UserAuthStyles";
import { getChartColor, barChartProps,barChartWidth } from "../InsightsHelper";
import { injectIntlTranslation } from '../../../../Common/Utilities/utility';
import { intlShape, injectIntl } from "react-intl";
import {
    USERS_LABEL, GROUP_TEXT, USERS_CHILD_GROUPS, USERS_PER_GROUP,
    INCLUDES, USERS_LOGIN_LABEL, USERS_LOGIN_TOOLTIP
} from '../../../../Common/Constants/constants';

const UserAvgLoginChart = ({ groupLoginStats, intl }) => {
    const { groupsStatData = [], totalGroups, chartGroupNames, groupStatIndexBy } = groupLoginStats;
    const { userTooltipSpan, userTooltipContainer, chartRoot, userAvgLoginRoot, totalUsersh, totalGroupsDiv, usersInfoContainer } = userAuthStyles();
    const groupsStatDataLength = groupsStatData.length;
    const { userAuthBar } = userAuthStyles({barChartWidth: barChartWidth(groupsStatDataLength)});
    const UserChartTooltip = (id, value) => (
        <div className={userTooltipContainer}>
            <span className={userTooltipSpan}>{GROUP_TEXT} {chartGroupNames[id]}</span>
            <h1 className={totalUsersh}>{USERS_LOGIN_TOOLTIP}: {Math.floor(value)} </h1>
        </div>
    )
    const childGroupsText = () =>
        <>
            {` (${injectIntlTranslation(intl, INCLUDES)} ${totalGroups} ${injectIntlTranslation(intl, USERS_CHILD_GROUPS)})`}
        </>
    return (
        <>
            { !!groupsStatDataLength &&
                <div className={userAvgLoginRoot}>
                    <div className={usersInfoContainer}>
                        <div className={totalGroupsDiv}>
                            <h1 className={totalUsersh}>
                                {USERS_LOGIN_LABEL} {!!totalGroups && childGroupsText()}
                            </h1>
                        </div>
                    </div>
                    <div id="UserAuthChart" className={userAuthBar}>
                        <ResponsiveBar
                            data={groupsStatData}
                            keys={Array.from({ length: groupsStatDataLength }, (_, i) => i)}
                            indexBy={groupStatIndexBy}
                            margin={{ top: 10, right: 10, bottom: 10, left: 40 }}
                            padding={0.50}
                            colors={getChartColor}
                            theme={barChartProps.theme}
                            tooltip={({ id, value, color }) => UserChartTooltip(id, value, color)}
                            defs={barChartProps.defs}
                            fill={barChartProps.fills}
                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={barChartProps.axisLeft}
                            enableGridY={false}
                            enableLabel={false}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            legends={[]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />
                    </div>
                </div>
            }
        </>
    )
}

UserAvgLoginChart.propTypes = {
    intl: intlShape.isRequired
};

export default injectIntl(memo(UserAvgLoginChart));