import React from 'react';
import Typography from '@material-ui/core/Typography';
import { REACTWRAPPERINSTALL, REACTAUTHWRAPPER, APPCONFIG, WRAPPER, LOGOUTCODE, GETPERMISSIONSCODE, GETUSERSBYPERMISSIONCODE, GETUSERSBYIDCODE, GETGROUPCODE, NPMRCFILEPATH } from '../../../../Common/Constants/constants';
import CopySnippet from '../CopySnippet/CopySnippet';
import useDeveloperTabStyles from './DeveloperTabStyles';
import appConfig from '../../../../Environment/environments';

export default function ReactTab(props) {
    const { appUrl } = props;
    const { instructions, refLink, step1 } = useDeveloperTabStyles();
    return (
        <div className={instructions}>
            <Typography>ReactAuthWrapper is a package which is built on top of auth-module. It can be used as a wrapper for root component of any react application to provide authentication. Below mentioned steps have to be followed to integrate ReactAuthWrapper in any react application.</Typography> <br />
            <Typography className={step1}><b>Step 1: </b>To download internal packages, it requires .npmrc file which has required information. Download the .npmrc file <a href={NPMRCFILEPATH} target="_blank">here </a>and place at "C:\Users\YourUserName" path.</Typography>
            <Typography><b>Step 2: </b>Install ReactAuthWrapper package as a dependency.</Typography>
            <CopySnippet copyCode={REACTWRAPPERINSTALL.replace("authVersion", appConfig.api.authVersion) } />
            <Typography><b>Step 3: </b>Wrap your application with ReactAuthWrapper or wherever required in the React App. Example in index.js, recommended usage:</Typography>
            <CopySnippet copyCode={REACTAUTHWRAPPER} />
            <Typography><b>Step 4 - optional and localhost: </b>Authentication works out of the box when your app is hosted on the same endpoint that is listed on carrierappshub.com URL field. For other environments or custom needs, especially when serving your app via localhost, you will need to inject props in "ReactAuthWrapper" component.</Typography> <br />
            <Typography>
                1) Create the appConfig object.
                The uiUrl of the application is your frontend endpoint listed in carrierappshub.com.
                The apiUrl of the application is your beckend endpoint of carrierappshub.com like https://api.carrierappshub1.com.
                The postLogoutRedirectUri is the redirect URL after login or logout. It has to match the Dev URL field on carrierappshub.com and your React App local url.
            </Typography>
            <CopySnippet copyCode={APPCONFIG.replace("appUrl", appUrl).replace("@apiUrl", appConfig.api.loginConfig.apiUrl)} />
            <Typography>
                2) Place whatever Component to Show after authentication inside tag with props as below example. Here appConfig containts the object which was created in previous step and getPermissions is a prop which will have a callback function that gets the permissions data once the authentication is successful. The props <i>errorScreenLogo, noPermissionText</i> are optional which can be used to display custom logo and text when user doesn't have permission.</Typography>
            <CopySnippet copyCode={WRAPPER} />
            <br />
            <Typography><b>Other functions which can be used are as follows:</b></Typography> <br />
            <Typography><b>1) Logout Code: </b>To Logout of Application use below code where you want to write your logout logic:</Typography>
            <CopySnippet copyCode={LOGOUTCODE} />
            <Typography><b>2) Fetch App Permissions code: </b>To fetch permissions and user info related to Application from UM use below code:</Typography>
            <CopySnippet copyCode={GETPERMISSIONSCODE} />
            <Typography><b>3) Fetch users based on permissions code: </b>To fetch users based on permissions related to Application from UM use below code:</Typography>
            <CopySnippet copyCode={GETUSERSBYPERMISSIONCODE} />
            <Typography><b>4) Fetch user details based on user id: </b>To fetch user details based on userid use below code:</Typography>
            <CopySnippet copyCode={GETUSERSBYIDCODE} />
            <Typography><b>5) Fetch group details based on group id: </b>To fetch group details based on groupid use below code:</Typography>
            <CopySnippet copyCode={GETGROUPCODE} />
            <Typography>
                Details related to parameters passed to above methods are as below:
                <p>1)apiUrl: API url used to fetch permissions related to app from UM(defaults to {appConfig.api.development} if not passed).</p>
                <p>2)applicationUrl: Application url to use in fetchAppPermissions call to UM (defaults to window.location.origin if not passed).</p>
                <p>3)successCallback: function used to invoke on successfull execution of api call.</p>
                <p>4)failureCallback: function used to invoke on any error in api call.</p>
                <p>5)reqObj: The request payload used in the FetchUsersBasedOnPermissions API call.</p>
                <p>6)userid: User Id of the user.</p>
                <p>7)groupid: group Id of the Group.</p>
                <div>
                    For further details refer the link -
                    <span>
                        <a className={refLink} target="_blank" href="https://scegithub.apps.carrier.com/SCE/ReactAuthWrapper">React Auth Wrapper</a>
                    </span>
                </div>
            </Typography>
        </div>
    );
}
