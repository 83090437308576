import { SET_PARENT_GROUP_FILTER, SET_USER_SELECTION, SET_USER_PERMISSIONS } from '../../Common/Constants/constants';

export const setParentGroupFilter = (value) => (dispatch) => {
    dispatch({
        type: SET_PARENT_GROUP_FILTER,
        data: { parentGroupFilter: value }
    });
}

export const setUsersSelection = (value) => (dispatch) => {
    dispatch({
        type: SET_USER_SELECTION,
        data: {usersList: value}
    })
}

export const setUserPermissions = (value,AppsSelection) => (dispatch) => {
    dispatch({
        type: SET_USER_PERMISSIONS,
        data: {userPermissions: value, AppsSelection: AppsSelection}
    })
}
