import React, { memo } from "react";
import {FILTER_GROUPS,FILTER_SELECTED_GROUP,FILTER_SELECTED_GROUP_CHILD} from '../../../../Common/Constants/constants';
import RightDrawer from '../../RightDrawer/RightDrawer';
import ChangeGroup from '../../../Groups/changeGroup/changeGroup';
import {CustomRadio} from "../../CustomRadio";
import userStatsFilterStyles from "./UserStatsFilterStyles";

const userStatsFilterState = {
    customWidth: 600,
    changeGroupHeight: "60vh",
    groupSelectionLabels: [FILTER_SELECTED_GROUP,FILTER_SELECTED_GROUP_CHILD],
};

const UserStatsGroupFilter = (props) => {
    const {selectedGroupName="",selectedGroupId="",groupSelectionType="1",changeGroupTypeSelection,saveGroupsData,hideFilter} = props;
    const {customWidth,changeGroupHeight,groupSelectionLabels} = userStatsFilterState;
    const {userStatsRadioContainer,userStatsRadio,userStatsRadioLabel} = userStatsFilterStyles();
    const changeGroupTypeHandler = (index) => changeGroupTypeSelection && changeGroupTypeSelection(index)
    const hideFilterHandler = () => hideFilter && hideFilter()
    const saveGroupHandler = (groupSelectionData) => saveGroupsData && saveGroupsData(groupSelectionData)
    return <RightDrawer customProps={{ width: customWidth }} open={true}>
        <ChangeGroup
            selectedGroupId={selectedGroupId}
            selectedGroupName={selectedGroupName}
            headerLabel={FILTER_GROUPS}
            customHeight={changeGroupHeight}
            callApiOnSave={false}
            onParentChange={saveGroupHandler}
            onClose={hideFilterHandler}
        >
            <div className={userStatsRadioContainer}>
                {groupSelectionLabels.map((label, index) => {
                    return (
                        <div className={userStatsRadio}>
                            <CustomRadio color="default" checked={(index === groupSelectionType)} onChange={() => changeGroupTypeHandler(index)} />
                            <span className={userStatsRadioLabel}>{label}</span>
                        </div>
                    )
                })}
            </div>
        </ChangeGroup>
    </RightDrawer>
}

export default memo(UserStatsGroupFilter);