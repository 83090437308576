import { SET_GLOBAL_ADMIN } from '../../Common/Constants/constants';

const initialState = {
   isGlobalAdmin : false
};

export default function globalAdminReducer(state = initialState, action){
    switch(action.type){
        case SET_GLOBAL_ADMIN:
            return {
                ...state,
                isGlobalAdmin: action.data.isGlobalAdmin,
            }
        default:
        return state;
    }
}