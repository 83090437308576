import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from '@material-ui/core/Checkbox';
import Chip from "@material-ui/core/Chip";
import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import { ADDORUPDATECATEGORY, CREATECATEGORY, ADDCATEGORY, CANCEL, ADD, FIELDREQ } from '../../../Common/Constants/constants';
import { usersAutoCompleteStyles } from "../../Shared/UsersAutoComplete/usersAutoCompleteStyles"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { TextField, Link, Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Tooltip from "@material-ui/core/Tooltip";
import DialogBox from "../../Shared/OptionsMenu/dialog";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  'addLink': {
    marginLeft: theme.spacing(1),
    paddingTop: "15px"
  },
  'errorText': {
    color: 'red'
  },
  'addButton': {
    color: "#FFFFFF",
    backgroundColor: "#0033AB",
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#0033AB',
    }
  }
}));

export default function CategoryDetails(props) {
  const classes = useStyles();
  const { categoryList = [], saveSelectedCategory, categoryId } = props;
  const [value, setValue] = useState(categoryId);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [open, toggleOpen] = React.useState(false);

  const getSelectedItem = () => {
    const item = categoryList.find((opt) => {
      if (opt.id == categoryId)
        return opt;
    })
    return item || null;
  }

  const { autoCompleteOptions, nonErrorBorder, autoCompleteDiv } = usersAutoCompleteStyles();

  const [dialogValue, setDialogValue] = React.useState({
    name: ''
  });

  const addCategory = (event) => {
    event.preventDefault();
    if (dialogValue.name !== "") {
      let data = { "Id": "0", "Name": dialogValue.name }
      axiosInstance.post(`${appConfig.api.development}${ADDORUPDATECATEGORY}`, data,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
        .then(res => {
          let val = { "id": res.data, "name": dialogValue.name }
          categoryList.push(val);
          setValue(val);
          saveSelectedCategory(val);
          handleClose();
        })
        .catch(e => {
          setMessage(e.response.data.message)
        });
    }
    else {
      setMessage(FIELDREQ)
    }
  };
  const handleClose = () => {
    setDialogValue({
      name: ''
    });

    toggleOpen(false);
  };

  const addClick = () => {
    toggleOpen(true);
    setMessage("");
    setDialogValue({
      name: ""
    });
  }

  return (
    <>
      <div className={autoCompleteDiv}>
        <Autocomplete
          // multiple
          id="checkboxes-tags-demo"
          options={categoryList}
          autoSelect={true}
          value={getSelectedItem()}
          onChange={(event, newValue) => {
            if(newValue !== null ) {
              setValue(newValue);
              saveSelectedCategory(newValue);
            }
          }}
          getOptionLabel={(option) => option.name || ""}
          getOptionSelected={(option, value) => option.id === value.id}
          style={{width: '98%' }}
          closeIcon=""
          renderInput={(params) => (
            <TextField {...params} variant="standard" />
          )}
        />
        <Tooltip title={CREATECATEGORY} className={classes.addLink}>
          <Link href="#" color="inherit"
            onClick={addClick}
          > Add </Link>
        </Tooltip>
        {/* <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ADDCATEGORY}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="normal"
              id="name"
              value={dialogValue.name}
              onChange={(event) => { setDialogValue({ ...dialogValue, name: event.target.value }); setMessage("") }}
              type="text"
            />
            <DialogContentText className={classes.errorText}>
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {CANCEL}
            </Button>
            <Button type="button" onClick={addCategory} className={classes.addButton} >{ADD} </Button>
          </DialogActions>
        </Dialog> */}
         <DialogBox dialogHead={ADDCATEGORY}   dialogContent={message}
      dialogButtonText={ADD} opennow={open} onCloseClick={addCategory} 
      onCloseEvent={handleClose}>
         <TextField
              autoFocus
              margin="normal"
              id="name"
              fullWidth
              value={dialogValue.name}
              onChange={(event) => { setDialogValue({ ...dialogValue, name: event.target.value }); setMessage("") }}
              type="text"
            />
        </DialogBox>
      </div>
    </>
  );
}