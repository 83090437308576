const version = '1.4.0.0';
const postLogOutUri = 'https://www.carrierappshub1.com';
const apiUri = 'https://api.carrierappshub1.com';
const authModuleVer = '2.1.9';
const storageBaseUri = 'https://staticfilesappshub.carrier.com/';

export const appConfig = {
  api: {
    development: apiUri, //User management API URL   
    postLogoutRedirectUri: postLogOutUri, //User management UI URL
    encryption: {
      IV: "8080808080808080",
      Key: "8080808080808080"
    },
    version: version,
    authVersion: authModuleVer,
    authSettings: {
      azureIss: "https://carriertools.b2clogin.com/9b47b473-c83a-4a42-810c-58426d62a85b/v2.0/",
      awsIss: "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_0ozfAABUx"
    },
    blobUrl: storageBaseUri + 'logo/',
    emailUrl:storageBaseUri+'email/',
    storageUrl: storageBaseUri,
    loginConfig: {
      apiUrl: apiUri + '/',
      uiUrl: postLogOutUri
    }
  }
}

export default appConfig
