import React, { Component } from 'react';
import downArrow from '../../../Assests/Images/arrow.svg';
import Arrow from '../../../Assests/Images/SettingsNotOpenedArrow.svg';
import './settingsStyles.scss';
import { apiGet } from '../Interceptor/apiService';
import Chip from '@material-ui/core/Chip';
import { GROUPAPPSETTINGSDETAILS, LOADINGTEXT, GETAPPSETTINGSCHILDREN, DESELECTALL, SELECTALL } from '../../../Common/Constants/constants';
import { encryptData } from '../../../Common/Utilities/utility';
import { CustomCheckbox } from "../CustomCheckBox";
import {checkboxSettings,toggleChildSettings,checkAllChildren,selectedSettingCount } from './SettingsChildrenHelper';

class SettingsChildrenDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Children: [],
      groupId: this.props.groupId,
      isChildrenDisplayed: false,
      LoadingChildren: false,
      isSelected: this.props.groupData.isSelected,
      totalSelectedChildCount: this.props.groupData.totalSelectedChildCount,
      totalChildCount: this.props.groupData.totalChildCount,
      type: this.props.type,
      settingID: (this.props.settingId) ? this.props.settingId : '',
      appID: this.props.appId,
      parentId: (this.props.settingId) ? this.props.settingId : null
    };
    this.updatedChildSettings = null;
  }

  changeCheckBoxSelection = () => {
    const totalChildCount = (!this.state.isSelected === true) ? this.props.groupData.totalSelectedChildCount : 0
    this.setState({
      isSelected: !this.state.isSelected,
      totalSelectedChildCount: totalChildCount
    }, () => {
      this.expandSettings("Single");
    });
  }

  expandSettings = (value) => {
    if(typeof value !== 'object' ){
      const { totalChildCount, isSelected, isChildrenDisplayed,
        Children: { length }, groupId, appID, settingID, parentId } = this.state;
      const { type } = this.props;
      if (totalChildCount > 0 && isSelected) {
        if (!isChildrenDisplayed) {
          if (length > 0) {
            this.setState({ isChildrenDisplayed: !isChildrenDisplayed });
          }
          else {
            this.getChildSettings(type, groupId, appID, settingID, parentId, isSelected, value);
            this.toggleLoading(true);
          }
        }
        else {
          this.toggleLoading(false);
        }
      }
      else {
        this.props.saveSettings(type, appID, settingID, isSelected, parentId, false, false);
      }
    }
    else{
      let child = value;
      let levelNo = (child["children"].length !== 0 && child.children[0].levelNo === 1) ? 0 : 1;
      if (child.totalChildCount > 0 && child.isSelected) {
        toggleChildSettings(child,levelNo);
      }
      this.setState({
        Children: this.updatedChildSettings
      });
    }
  }

  shouldSelectAllChildren = (value, child) => {   
    const { groupId, appID, settingID, parentId } = this.state;
    const { type, saveSettings } = this.props;

    if(child === ""){
      const totalChilds = (value === true) ? this.props.groupData.totalChildCount : 0;
      if (value === true) {
        this.setState({
          isSelected: value
        }, () => {
          this.getChildSettings(type, groupId, appID, settingID, parentId, this.state.isSelected, "Multiple", true);
          this.toggleLoading(true);
        });
      } else {
        this.setState({
          isSelected: value,
          totalSelectedChildCount: totalChilds
        }, () => {
          if(this.updatedChildSettings !== null){
            this.saveChildSettings(this.updatedChildSettings, value, appID, parentId,0, true);
          }
          saveSettings(type, appID, settingID, value, parentId, !value, false, false);
        });
      }
    }
    else{
      if (child) {
        checkAllChildren(child, value, appID, parentId,saveSettings,this.updatedChildSettings);
      }
      saveSettings("setting", appID, child.settingId, value, parentId, !value);
      this.setState({
        Children: this.updatedChildSettings,
        totalSelectedChildCount: selectedSettingCount(this.updatedChildSettings)
      });
    }
  }

  getChildSettings = async (type, groupId, appId, settingId, parentId, isSelected, settingValue, isManual = false) => {
    const {totalChildCount ,totalSelectedChildCount} =  this.props.groupData;
    let groupID = `?groupId=${encryptData(groupId).toString()}`;
    let appID = groupId ? `&appId=${encryptData(appId).toString()}` : `?appId=${encryptData(appId).toString()}`;
    let settingID = `&settingId=${encryptData(settingId).toString()}`;
    let urlWithParameters;
    if (this.props.groupId) {
      urlWithParameters = (this.state.type.toLowerCase() === 'setting') ? (GETAPPSETTINGSCHILDREN + groupID + appID + settingID) : (GETAPPSETTINGSCHILDREN + groupID + appID);
    }
    else {
      urlWithParameters = (this.state.type.toLowerCase() === 'setting') ? (GETAPPSETTINGSCHILDREN + appID + settingID) : (GROUPAPPSETTINGSDETAILS + appID)
    }
    const { data } = await apiGet(urlWithParameters, this.getChildSettingsFail);
    this.props.saveSettings(type, appId, settingId, isSelected, parentId, !isSelected, false, true);
    if (settingValue === "Multiple") {
      this.updatedChildSettings = this.saveChildSettings(data, isSelected, appId, parentId,1, isManual);
    }
    else {
      this.updatedChildSettings = this.saveChildSettings(data, "default", appId, parentId,0, isManual);
    }
    this.setState({
      Children: this.updatedChildSettings,
      isChildrenDisplayed: true,
      totalSelectedChildCount : (settingValue === "Multiple") ?  totalChildCount : totalSelectedChildCount,
      LoadingChildren: false
    });
    
  }

  getChildSettingsFail = () =>
    this.setState({
      Children: [],
      LoadingChildren: false
  });

  saveChildSettings(data, isSelected, appId, parentId, levelNo, isManual = true) {
    let result = data.map((setting) => {
      setting.isSelected = (isSelected === "default") ? setting.isSelected : isSelected;
      if (isSelected !== "default") {
        setting.levelNo = levelNo;
        setting.totalSelectedChildCount = setting.totalChildCount;
      }
      else{
        setting.totalSelectedChildCount = setting.totalSelectedChildCount;
      }
      this.props.saveSettings("setting", appId, setting.settingId, setting.isSelected, parentId, false, false, !isManual);
      if (setting.children) {
        this.saveChildSettings(setting.children, isSelected, appId, parentId,levelNo, isManual);
      }
      return setting;
    });
    return result;
  }

  checkboxChildSettings = (event, child, isManual = true) => {
    
    const { appID, parentId } = this.state;
    const {saveSettings} = this.props;

    saveSettings("setting", appID, child.settingId, event.target.checked, parentId, !event.target.checked, false, !isManual);
    checkboxSettings(event.target.checked,child,appID, parentId,saveSettings,this.updatedChildSettings,)
    this.setState({
      Children: this.updatedChildSettings,
      totalSelectedChildCount: selectedSettingCount(this.updatedChildSettings)
    });
  };

  displayChildren = (unSelectByDefault, Children, TreeLevel) => {
    const { isChildrenDisplayed, isSelected } = this.state;
    if (Children !== null && Children !== undefined && Children && Children.length > 0) {
      if (isSelected && isChildrenDisplayed) {
        let Indentation = 16;
        return (
          Children.map((child, index) => {
            return (
              <div className="settingsli" style={{ paddingLeft: Indentation + "px" }}>
                <div className={(child.levelNo === 1) ? "setting" : "setting settingsDisplay"}>
                  <div>
                    <CustomCheckbox
                      key={index}
                      color="default"
                      checked={child.isSelected}
                      onChange={(event) => this.checkboxChildSettings(event, child)}
                    />
                  </div>
                  { this.settingName(child.isSelected,child.settingName,child.totalChildCount,child) }
                  { (child.totalChildCount > 0 && !unSelectByDefault) &&
                      this.chipContainer(child.totalChildCount,child.totalSelectedChildCount,child) }
                  { (child.totalChildCount > 0) && this.getChildTreeIcon(child) }
                </div>
                {
                  (child.children && child.children.length > 0) &&
                  this.displayChildren(unSelectByDefault, child.children, TreeLevel = TreeLevel + 1)
                }
              </div>
            )
          })
        )
      }
      else {
        this.setState({
          Children: [],
          isChildrenDisplayed: false
        });
        return null;
      }
    } else {
      return null;
    }
  }

  toggleLoading = (showLoader) => {
    this.setState({
      LoadingChildren: showLoader,
      isChildrenDisplayed: !this.state.isChildrenDisplayed
    });
  }

  getTreeIcon = (isSelected) => {
    if (this.state.isChildrenDisplayed)
      return (
        <div className="SettingsOpenedContainer">
          <img className="SettingsIconHover" src={downArrow} onClick={() => this.expandSettings("Single")} />
        </div>
      )
    else {
      return (
        <div className={(isSelected) ? "SettingsNotOpenedContainer" : "SettingsNotOpenedDisabledContainer"}>
          <img className="SettingsArrowRightIcon" src={Arrow} onClick={() => this.expandSettings("Single")} />
        </div>
      )
    }
  }

  getChildTreeIcon = (child) => {
    if (child.children[0].levelNo === 1)
      return (
        <div className="SettingsOpenedContainer">
          <img className="SettingsIconHover" src={downArrow} onClick={() => this.expandSettings(child)} />
        </div>
      )
    else {
      return (
        <div className={(child.levelNo === 1 && child.isSelected) ? "SettingsNotOpenedContainer" : "SettingsNotOpenedDisabledContainer"}>
          <img className="SettingsArrowRightIcon" src={Arrow} onClick={() => this.expandSettings(child)} />
        </div>
      )
    }
  }

  selectDeselectAll = (value) => {
    const child = (typeof value != 'object') ? "" : value;
    return (
      <div className={"SelectDeselectContainer SelectDeselectMargin"}>
        <span className={"DeselectAll"} onClick={(e) => this.shouldSelectAllChildren(false,child)}>
          {DESELECTALL}
        </span>
        |
        <span className={"SelectAll"} onClick={(e) => this.shouldSelectAllChildren(true,child)}>
          {SELECTALL}
        </span>
      </div>
    )
  }

  chipContainer = (totalChildCount,totalSelectedChildCount,value) => {
    const child = (value === "") ? "Single" : value;
    return(
      <div className="ChipContainer ChipContainerSettings">
        {(totalChildCount > 0) ?
          <Chip
            label={totalSelectedChildCount}
            onClick={() => this.expandSettings(child)}
            className="SelectedCountChip"
          />
          :
          <Chip
            label={totalSelectedChildCount}
            className="SelectedCountChip"
          />}
        <div className="CountDivider">/</div>
        <Chip label={totalChildCount} className="TotalCountChip" />
      </div>
    )
  }

  settingName = (isSelected,name,totalChildCount,value) => {
    const child = (value === "") ? "Single" : value;
    return(
      <div
        className="SettingsExpendedCell"
        style={{ width: "93%", display: "inline-flex" }}>
        <div className="SettingsExpandedCellLabel"
          onClick={() => this.expandSettings(child)}>{name}
        </div>
        {
          (totalChildCount > 0) && this.selectDeselectAll((value === "")? isSelected : value)
        }
      </div>
    )
  }

  displayLoadingBar = () => {
    if (this.state.LoadingChildren) {
      return <span>{LOADINGTEXT}</span>
    }
    return null;
  }

  render() {
    const { unSelectByDefault, TreeLevel, name } = this.props;
    const { Children, isSelected, totalChildCount, totalSelectedChildCount } = this.state;
    let Indentation = 16;
    let SettingsIndentation = unSelectByDefault ? 71 : 107;
    let SettingsExpendedCellIndentation = (Indentation * TreeLevel) + 'px';
    if (TreeLevel == 0) {
      Indentation = 0;
      SettingsExpendedCellIndentation = SettingsIndentation + 'px';
    }
    return (
      <div className="settingsli" style={{ paddingLeft: Indentation + "px" }}>
        <div className="setting">
          <div>
            <CustomCheckbox
              color="default"
              checked={isSelected}
              onChange={this.changeCheckBoxSelection}
            />
          </div>
          { this.settingName(isSelected,name,totalChildCount,"") }
          { (totalChildCount > 0 && !unSelectByDefault) &&
              this.chipContainer(totalChildCount,totalSelectedChildCount,"") }
          { (totalChildCount > 0) && this.getTreeIcon(isSelected) }
        </div>
        {this.displayLoadingBar()}
        {this.displayChildren(unSelectByDefault, Children, TreeLevel)}
      </div>
    )
  }
}
export default SettingsChildrenDropDown;