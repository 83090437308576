import React, { memo, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { InputAdornment, OutlinedInput } from '@material-ui/core';
import DomainsStyles from "../Domains/DomainsStyles";
import {
  GET_ADMIN_CONFIGURATION, INACTIVITYDURATION, INACTIVITYDURATIONPARA1, INACTIVITYDURATIONPARA2, REMAINDERFREQUENCY,
  REMAINDERFREQUENCYPARA1, INACTIVE_DURATION, INACTIVEERRORMESSAGE, WEEKS, CHANGE_ADMIN_CONFIGURATION, ENTER,
  API_ACTIVITY_ERROR, API_ACTIVITY_SUCCESS, MINVALUE, MAXVALUE, GROUPTIMEOUTFREQ, GROUPTIMEOUTFREQSUB
} from '../../../../Common/Constants/constants';
import { apiGet, apiPut } from '../../Interceptor/apiService';
const UserInactivity = (props) => {
  const [activityState, setActivityState] = useState({
    durationState: [],
    frequencyState: [],
    duration: "",
    frequency: "",
    groupTimeoutState: [],
    groupTimeout: ""
  });

  const { durationState, frequencyState, duration, frequency, groupTimeoutState, groupTimeout } = activityState;
  const regEx = /^[1-9][0-9]?$|^100$/;
  useEffect(() => {
    getAdminConfiguration();
  }, [])

  const getAdminConfiguration = async () => {
    const { data } = await apiGet(GET_ADMIN_CONFIGURATION, getAdminConfigFailure);
    data.length !== 0 ? getAdminConfigSuccess(data) : getAdminConfigFailure()
  }

  const getAdminConfigSuccess = (data) => {
    const newActivityState = {
      durationState: data[0],
      frequencyState: data[1],
      duration: data[0].configurationValue,
      frequency: data[1].configurationValue,
      groupTimeoutState: data[3],
      groupTimeout: data[3].configurationValue
    }
    setActivityState({ ...activityState, ...newActivityState });
  }

  const getAdminConfigFailure = () => {
    setActivityState({ ...activityState });
  }
  const errorMessage = INACTIVEERRORMESSAGE;
  const { domainContainer, retentionInputContainer, domainErrorMessage, hrLine } = DomainsStyles();

  const setActivity = (prop) => (event) => {

    if ((regEx.test(event.target.value)) && (event.target.value !== activityState[prop])) {
      setActivityState({ ...activityState, [prop]: event.target.value });
    }
  }
  const handlerActivity = (event, stateVal) => {
    if ((regEx.test(event.target.value)) && (event.target.value !== stateVal.configurationValue)) {
      const apiInput = {
        id: stateVal.id,
        configurationKey: stateVal.configurationKey,
        configurationValue: event.target.value
      }
      saveActivity(apiInput)
    }
  }

  const keyPressed = (e, stateVal) => {
    if (e.key === ENTER) {
      handlerActivity(e, stateVal);
    }
  }

  const saveActivity = async (apiInput) => {
    const { data } = await apiPut(CHANGE_ADMIN_CONFIGURATION, apiInput, apiFailure);
    props.UserInactivityError(API_ACTIVITY_SUCCESS, 'success');
    const key = apiInput.configurationKey === INACTIVE_DURATION ? 'durationState' : 'frequencyState';
    setActivityState({ ...activityState, [key]: data });
  }

  const apiFailure = () => {
    props.UserInactivityError(API_ACTIVITY_ERROR, 'error');
  }
  return (
    <div>
      {<hr className={hrLine} />}
      <h3>{INACTIVITYDURATION}</h3>
      <div className={domainContainer}>
        <p>{INACTIVITYDURATIONPARA1}</p>
        <OutlinedInput
          type="number"
          className={retentionInputContainer}
          id="outlined-adornment-duration"
          value={activityState.duration}
          onChange={setActivity('duration')}
          onBlur={(event) => handlerActivity(event, durationState)}
          onKeyDown={(event) => keyPressed(event, durationState)}
          endAdornment={<InputAdornment position="end">weeks</InputAdornment>}
          aria-describedby="outlined-weeks-helper-text"
          inputProps={{ min: MINVALUE, max: MAXVALUE }}
        />
        <p>{INACTIVITYDURATIONPARA2}</p>
      </div>
      {activityState.duration && <span className={domainErrorMessage}>{(duration < MINVALUE || duration > MAXVALUE) ? errorMessage : ""}</span>}
      {<hr className={hrLine} />}
      <h3>{REMAINDERFREQUENCY}</h3>
      <div className={domainContainer}>
        <p>{REMAINDERFREQUENCYPARA1}</p>
        <OutlinedInput
          className={retentionInputContainer}
          id="outlined-adornment-frequency"
          type="number"
          value={activityState.frequency}
          onChange={setActivity('frequency')}
          onBlur={(event) => handlerActivity(event, frequencyState)}
          onKeyDown={(event) => keyPressed(event, frequencyState)}
          endAdornment={<InputAdornment position="end">{WEEKS}</InputAdornment>}
          inputProps={{ min: MINVALUE, max: MAXVALUE }}
        />
      </div>
      {activityState.frequency && <span className={domainErrorMessage}>{(frequency < MINVALUE || frequency > MAXVALUE) ? errorMessage : ""}</span>}
      {<hr className={hrLine} />}
      <h3>{GROUPTIMEOUTFREQ}</h3>
      <div className={domainContainer}>
        <p>{GROUPTIMEOUTFREQSUB}</p>
        <OutlinedInput
          className={retentionInputContainer}
          id="outlined-adornment-frequency"
          type="number"
          value={activityState.groupTimeout}
          onChange={setActivity('groupTimeout')}
          onBlur={(event) => handlerActivity(event, groupTimeoutState)}
          onKeyDown={(event) => keyPressed(event, groupTimeoutState)}
          endAdornment={<InputAdornment position="end">{WEEKS}</InputAdornment>}
          inputProps={{ min: MINVALUE, max: MAXVALUE }}
        />
      </div>
      {activityState.frequency && <span className={domainErrorMessage}>{(frequency < MINVALUE || frequency > MAXVALUE) ? errorMessage : ""}</span>}
    </div>
  )

}
export default injectIntl(memo(UserInactivity));
