import React, { useState } from "react";
import DialogBox from '../../../Shared/OptionsMenu/dialog';
import TextField from '@material-ui/core/TextField';
import permissionStyle from './permissionStyle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { FIELDREQ, GROUPLENGTH, SAVETEXT, ADDPERMISSIONSTEXT, UPDATEPERMISSIONSTEXT,PERMISSIONSUNIQUEFAIL, PERMISSIONSSAVEFAIL } from '../../../../Common/Constants/constants';
import { addPermission, updatePermission } from "./permissionUtil";


const UpsertPermission = (props) => {
  const{ onCloseEvent, settingName, getPermissions, isEdit, settingId, appId, isRootPermission, handleListClick, treeExpandStatus} = props
 const { errorText } = permissionStyle();
 const [permissionError, setpermissionError] = useState('');
 const getPermissionName = () =>{
    return isEdit? settingName : "";
 }
 const [permissionName, setpermissionName] = useState(getPermissionName());


 const handleTextFieldChange = (event) => {
    setpermissionName(event.target.value);
    setpermissionError(validationPermission(event.target.value));
    }
  const onClosePermissionEvent = () => {
    setpermissionError('');
    setpermissionName('');
    onCloseEvent();
  }

  const validationPermission = (permissionName) => {
    return (permissionName.trim() == "") ? FIELDREQ : (permissionName.length > 100 ? GROUPLENGTH : "")
  }

  const onSaveClick = async () => {
    try {
      const error = validationPermission(permissionName);
      setpermissionError(error)
      if (!error) {
        if (isEdit) {
          await updatePermission(settingId, permissionName);
        }
        else {
          await addPermission(isRootPermission, appId, settingId, permissionName);
          if (!isRootPermission && !treeExpandStatus) {
            handleListClick();
          }
        }
        onClosePermissionEvent();
        getPermissions();
      }
    } catch (error) {
      let errorMessage = (error.response.data.message.indexOf("UniqueKey Constraints failed") > -1) ? PERMISSIONSUNIQUEFAIL : PERMISSIONSSAVEFAIL;
      setpermissionError(errorMessage);
    }
  }

  
    return (
      <DialogBox dialogHead={isEdit? UPDATEPERMISSIONSTEXT : ADDPERMISSIONSTEXT} 
      dialogButtonText={SAVETEXT} opennow onCloseClick={onSaveClick} 
      onCloseEvent={onClosePermissionEvent}>
         <TextField
            autoFocus
            margin="dense"
            label="Permission Name"
            fullWidth
            onChange={handleTextFieldChange}
            value={permissionName}
          />
          {permissionError &&
            <span className={errorText}>{permissionError}</span>}
        </DialogBox>
    )
  }
  
  export default withMobileDialog()(UpsertPermission);
