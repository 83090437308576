import React from 'react';
import Typography from '@material-ui/core/Typography';
import { PYTHONFRAMEWORKSUPPORT,PYTHONMIDDLEWAREINSTALL,APPLYAUTHFILTER,PYTHONGENERALINSTRUCTION,PYTHONSTEP1,PYTHONREQTXT, PYTHONADDTDOC, PYTHONENVVAR,PYTHONSTEP2,PYTHONSTEP2INSTRUCTION,APPLYDECORATOR,PYTHONSTEP3INSTRUCTION, PYTHONSTEP3} from '../../../../Common/Constants/constants';
import CopySnippet from '../CopySnippet/CopySnippet';
import useDeveloperTabStyles from './DeveloperTabStyles';

export default function PythonTab() {
    const { instructions, refLink } = useDeveloperTabStyles();
    return (
        <div className={instructions}>
        <Typography>{PYTHONGENERALINSTRUCTION}</Typography> <br />
        <Typography>{PYTHONFRAMEWORKSUPPORT}</Typography><br/>
        <Typography><b>{PYTHONSTEP1} </b>
        Install python middleware package as a dependency in your requirement.txt file. Download the file from <a href={PYTHONREQTXT} target="_blank">here</a> and copy the content inside your requirement.txt file.
        </Typography><br/>
        <Typography>Version Compatibility: We support upto Python 3.11
            <li>For Python 3.7, use auth_wrapper {'>'}=3.7 & {'<'}3.8</li>
            <li>For Python 3.8, use auth_wrapper {'>'}=3.8 & {'<'}3.9</li>
            <li>For Python 3.9, use auth_wrapper {'>'}=3.9 & {'<'}3.10</li>
            <li>For Python 3.10, use auth_wrapper {'>'}=3.10 & {'<'}3.11</li>
            <li>For Python 3.11, use auth_wrapper {'>'}=3.11 & {'<'}3.12</li>
        </Typography><br/>
        <Typography><b>{PYTHONSTEP2} </b>{PYTHONSTEP2INSTRUCTION}</Typography>
        <CopySnippet copyCode={APPLYAUTHFILTER} />
        <Typography><b>{PYTHONSTEP3}</b>{PYTHONSTEP3INSTRUCTION}</Typography>
        <CopySnippet copyCode={APPLYDECORATOR}></CopySnippet>
        <Typography>The user_details parameter in your endpoint function is optional. If included, it will contain the decoded payload from the JWT token after successful authentication. This allows you to access user-specific information within your endpoint logic if needed.</Typography><br/>
        <Typography>'Encryption key' is required and mandatory to be passed as environment variable. Download the file from <a href={PYTHONENVVAR} target="_blank">here</a> which contains the encryption key & value, set this as environment variable in your application</Typography><br/>
        <div>
            For further details on different frameworks refer the link -
            <span>
                <a className={refLink} target="_blank" href={PYTHONADDTDOC}>Python Middleware</a>
            </span>
        </div>
        </div>
    );
}
