import React,{useState,useEffect,forwardRef,useImperativeHandle} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import { SendInvitationStyles, EXTERNALEMAILREQUIREDERROR,DELETE_TEMPLATE,DEFAULTVALUE ,EMAILSUBJECT,CUSTOMMESSAGE,CUSTOMEMAIL,ENTERMESSAGE,ENTERSUBJECT,CUSTOMTITLE,ENTERTITLE,CUSTOMICON,ICONERROR} from '../../../Common/Constants/constants';
import { intlShape, injectIntl } from "react-intl";
import { injectIntlTranslation,encryptData } from "../../../Common/Utilities/utility";
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import appConfig from '../../../Environment/environments';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import VisibilityIcon from '@material-ui/icons/Visibility';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import EmailTemplatePreview from './emailTemplatePreview';
import Drawer from '@material-ui/core/Drawer';
const useStyles= makeStyles(SendInvitationStyles, { withTheme: true }); 

const CustomEmailTemplate = forwardRef((props,ref) =>{
    const { intl } = props;
    const classes = useStyles();
    const [templateDetails, setTemplateDetails] = useState({id:'',groupId:'',supporEmail:'',subject:'',body:'',isSelected:false,isModified:false,name:'',title:'',icon:'',bannerExt:'',banner:''});
    const [emailError, setEmailError] = useState('');
    const [supportEmail, setSupportEmail] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [saveTemplateEnable, setSaveTemplateEnable] = useState(true);
    const [emailTitle, setEmailTitle] = useState('');
    const [filename, setFileName] = useState('No file chosen');
    const [previewDrawer, setPreviewDrawer] = useState({open: false });
    const [iconError, setIconError] = useState('');

    useEffect(()=>{
      setFileName((props.default.banner === null||props.default.banner ==="")?'No file chosen':(props.default.id+"."+props.default.banner))
      setTemplateDetails(prevData => {
        return {
          id:props.default.id,
          name:props.default.name,
          groupId:props.groupId,
          title:(props.default.body===null||props.default.body==='')?'':props.default.body,
          supporEmail: props.default.support,
          subject:props.default.subject,
          body:props.default.message,
          isSelected:props.default.isSelected,
          isModified:false,
          bannerExt:(props.default.banner==null||props.default.banner==='')?'':props.default.banner,
          icon:''     
          }
    });
    },[props.default]);

    const setSelectedValue=(e,value)=>{
      if(e.target.tagName==='LI')
      {
          setEmailBody('');
          setEmailSubject('');
          setSupportEmail('');
          setSaveTemplateEnable(true)
          setFileName('No file chosen')
          setEmailTitle('')
          setTemplateDetails(prevData => {
              return {
                id:value.id,
                name:value.name,
                groupId:props.groupId,
                supporEmail: value.support,
                subject:value.subject,
                title:value.body,
                body:value.message,
                isSelected:value.isSelected,
                isModified:false,
                bannerExt:value.banner,
                icon:''       
                }
          });
          props.templateDetailsUpdate({ 
                  id:value.id,
                  name:value.name,
                  groupId:props.groupId,
                  supporEmail: value.support,
                  subject:value.subject,
                  title:value.body,
                  body:value.message,
                  isSelected:value.isSelected,
                  bannerExt:value.banner,
                  isModified:false,
                  icon:''  
                 })
                  props.updateSelectedValue(value)
      }
     
    }
  function  cleanTemplateForm (){
    openEmailPreview(false)
    setIconError('')
      setEmailBody('');
      setEmailSubject('');
      setSupportEmail('');
      setSaveTemplateEnable(true)
      setFileName('No file chosen')
      setEmailTitle('')
      setTemplateDetails(prevData => {
        return {
          id:'',
          groupId:'',
          supporEmail: '',
          name:'',
          title:'',
          subject:'',
          body:'',
          icon:'',
          isSelected:false,
          isModified:false,
          bannerExt:'',
          banner:''    
          }
    });
    }
    useImperativeHandle(ref, () => ({
      cleanTemplateForm
  }));

    const validateSupportEmail=(e)=>{
      setSupportEmail(e.target.value)
     let isNotValidEmail= props.supportEmailValidator(e.target.value );
     if(isNotValidEmail)
     {
     setEmailError(EXTERNALEMAILREQUIREDERROR)
      enabledInviteButton(false)
      setSaveTemplateEnable(true)
     }
     else
     {
      setSaveTemplateEnable(false)
     setEmailError('')
     enabledInviteButton(true)
     props.templateDetailsUpdate({...templateDetails,groupId:props.groupId,supporEmail:e.target.value,isModified:true})
     setTemplateDetails({...templateDetails,supporEmail:e.target.value,isModified:true});
     }
    }
    const enabledInviteButton=(enableButton)=>{
      if(enableButton)
      return props.enabledInviteButton(true) ;
      else
      return props.enabledInviteButton(false) ;
    }
    const onEmailBodyChange=(e)=>{
      setEmailBody(e.target.value)
      setSaveTemplateEnable(false)
      props.templateDetailsUpdate({...templateDetails,groupId:props.groupId,body:e.target.value,isModified:true})
      setTemplateDetails({...templateDetails,body:e.target.value,isModified:true});
    }
    const onEmailSubjectChange=(e)=>{
      setEmailSubject(e.target.value)
      setSaveTemplateEnable(false)
      props.templateDetailsUpdate({...templateDetails,groupId:props.groupId,subject:e.target.value,isModified:true})
      setTemplateDetails({...templateDetails,subject:e.target.value,isModified:true});
   
    }
    const onEmailTitleChange=(e)=>{
      if(e.target.value!=' ')
      {
        setEmailTitle(e.target.value)
      setSaveTemplateEnable(false)
      props.templateDetailsUpdate({...templateDetails,groupId:props.groupId,title:e.target.value,isModified:true})
      setTemplateDetails({...templateDetails,title:e.target.value,isModified:true});
      }
      else
      setTemplateDetails({...templateDetails,isModified:false});
    }
    const deleteEmailtemplate=(e,id,index)=>{
      if(e.target.tagName==='svg'||e.target.tagName==='path'){
             const url = DELETE_TEMPLATE;
      axiosInstance.delete(appConfig.api.development + url+ encryptData(id).toString()+"&bannerExt="+templateDetails.bannerExt, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res){
          cleanTemplateForm()
          if(id=== parseInt(localStorage.getItem("emailTemplateId")))
          {
          localStorage.setItem("emailTemplateId", 1);
          }
          props.loadCustomInviteTemplate()
      }
      })
       }
  }

 const onSaveClick=(e)=>{
    if(templateDetails.isModified==true)
    props.onSaveTemplateButtonHandler(e)
  }

  const onIconUpload=(e)=>
  {
    setTemplateDetails({...templateDetails,bannerExt:''});
    setIconError('')
    setFileName('No file chosen')
    setSaveTemplateEnable(false)
    var fileSizeCheck = 0;
    var fileTypeCheck = false;
    fileSizeCheck = (e.target.files[0]) ? (e.target.files[0].size <= 2200000) : false;
    fileTypeCheck = (e.target.files[0]) ? ((e.target.files[0].type === "image/jpeg") || (e.target.files[0].type === "image/png") || (e.target.files[0].type === "image/bmp")|| (e.target.files[0].type === "image/jpg")) : false;
    let reader = new FileReader();
    if (fileSizeCheck && fileTypeCheck) {
      setFileName(e.target.files[0].name)
      let URL = window.URL || window.webkitURL;
      let imageURL = (e.target.files[0]) ? URL.createObjectURL(e.target.files[0]) : '';
      let setImageType=e.target.files[0].type.split('/')[1]==="svg+xml"?e.target.files[0].type.split('/')[1].split("+")[0]:e.target.files[0].type.split('/')[1]
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = function () {
        let imageBaseData=reader.result.split(',')[1]
      setTemplateDetails({...templateDetails,icon:imageURL,isModified:true,banner:imageBaseData,bannerExt:setImageType});
      props.templateDetailsUpdate({...templateDetails,groupId:props.groupId,icon:imageURL,isModified:true,banner:imageBaseData,bannerExt:setImageType})
      
      }
    }
    else{
      setSaveTemplateEnable(true)
      setIconError(injectIntlTranslation(props.intl, ICONERROR))
      setTemplateDetails({...templateDetails,icon:'',isModified:false});
    }
  }
  const clearFileContent = (e) => {
    e.target.value = '';
  }

  const openEmailPreview = (isPreviewEnabled) =>{
    setPreviewDrawer({ open: isPreviewEnabled});

  }
  const closeEmailPreview = () =>
  {
    setPreviewDrawer({ open: false, offset: "0px" });
    setTemplateDetails({...templateDetails,icon:'',});
  }
  const previewList = side => (
    <div
      role="preview"
    >
    <EmailTemplatePreview sendEmailTo={props.emailReciever} loggedInUserName={props.loggedInUserName} groupName={props.groupName} onClick={closeEmailPreview} templateData={templateDetails}></EmailTemplatePreview>
    </div>
  );
  
return(
  
  props.customEmailTemplates &&(
        <>
          <span className={classes.customInviteSelectSpan}>
          <Autocomplete
          className={classes.customInviteSelect}
          id="ddlCustomTemplate"
          options={props.customEmailTemplates}
          value={props.customEmailTemplates!=undefined?props.default:""}
          autoSelect={true}
          onChange={setSelectedValue}
          renderOption={(props, option,index) => (
            
            <span className={classes.liAutoComplete}>
            <li {...props} className={classes.liAutocomplete}>
              {props.name}
            </li>
            {props.id!=1?
             <DeleteIcon
             disabled
             className={classes.deleteIcon}
            onClick={(e) => deleteEmailtemplate(e,props.id,index)}
           />:<></>
          }
            </span>
          )}
          getOptionLabel={(option) => option.name || ""}
          getOptionSelected={(option, value) => option.id === value.id}
          closeIcon=""
          renderInput={(params) => (
            <TextField {...params} />
          )}
        /> 
              <Tooltip title={"Save"} arrow>
              <span>
              <IconButton disabled={saveTemplateEnable} aria-label="delete" size='small' className={classNames(classes.root,classes.customHoverFocus)} >
              <SaveIcon  type="submit" className={classes.saveIcon} onClick={onSaveClick} ></SaveIcon>
              </IconButton>
              </span>
              </Tooltip>
              <Tooltip title={"Preview"} arrow>
                <span>
              <IconButton  aria-label="delete" size='small' className={classNames(classes.root,classes.customHoverFocus)} >
                <VisibilityIcon  className={classes.saveIcon} onClick={openEmailPreview} ></VisibilityIcon>
              </IconButton>
              </span>
              </Tooltip>
              </span>
              <span className={classes.customInviteBodySubSpan}>
              <TextField
              style={{"color":"white"}}
              placeholder={injectIntlTranslation(props.intl, ENTERSUBJECT)}
              label={injectIntlTranslation(props.intl, EMAILSUBJECT)}
              required
              value={((templateDetails!=null || templateDetails!=undefined)  && emailSubject=='')?templateDetails.subject:emailSubject}
              InputLabelProps={{
                classes: {
                  root: classes.labelRootCutomizeTemplate,
                  focused: classes.labelFocused
                },
                required: false,
                shrink: true,
              }}
              autoComplete="off"
              onChange={onEmailSubjectChange}
              margin="normal" />
              </span>
              <span className={classes.iconSpan}>
              <span >{injectIntlTranslation(props.intl,CUSTOMICON)}</span>
              <span className={classes.iconUpload}>
              <input type='file' id="file"  className={classes.bgImageStyle} onChange={(e) => onIconUpload(e)} onClick={(e) => clearFileContent(e)} />
              <span className={classes.fileNameSpan}>{filename}</span>
              </span>
              </span>
              {iconError!=''?
              <span className={classes.spanError} >{iconError}</span>:<></>
              }
              <span className={classes.customInviteBodySubSpan}>
              <TextField
            style={{"color":"white"}}
            placeholder={injectIntlTranslation(props.intl, ENTERTITLE)}
            label={injectIntlTranslation(props.intl,CUSTOMTITLE)}
              required
              value={((templateDetails!=null || templateDetails!=undefined)  && emailTitle=='')?templateDetails.title:emailTitle}
              InputLabelProps={{
                classes: {
                  root: classes.labelRootCutomizeTemplate,
                  focused: classes.labelFocused
                },
                required: false,
                shrink: true
              }}
              onChange={onEmailTitleChange}
              autoComplete="off"
              margin="normal" />
              </span>
              <span className={classes.customInviteBodySubSpan}>
              <TextField
            style={{"color":"white"}}
            placeholder={injectIntlTranslation(props.intl, ENTERMESSAGE)}
            label={ injectIntlTranslation(props.intl,CUSTOMMESSAGE)}
              required
              value={((templateDetails!=null || templateDetails!=undefined)  && emailBody=='')?templateDetails.body:emailBody}
              InputLabelProps={{
                classes: {
                  root: classes.labelRootCutomizeTemplate,
                  focused: classes.labelFocused
                },
                required: false,
                shrink: true
              }}
              onChange={onEmailBodyChange}
              autoComplete="off"
              margin="normal" />
              </span>
              <span className={classes.customInviteBodySubSpan}>
          <TextField
              style={{"color":"white"}}
              label="Support Email"
              placeholder={injectIntlTranslation(props.intl, CUSTOMEMAIL)}
              required
              value={((templateDetails!=null || templateDetails!=undefined)  && supportEmail=='')?templateDetails.supporEmail:supportEmail}
              InputLabelProps={{
                classes: {
                  root: classes.labelRootCutomizeTemplate,
                  focused: classes.labelFocused
                },
                required: false,
                shrink: true,
              }}
              onChange={validateSupportEmail}
              autoComplete="off"
              margin="normal" />
              </span>
              {emailError!=''?
              <span className={classes.spanError} >{emailError}</span>:<></>
              }
               {previewDrawer.open && (
                <Drawer anchor="right"  open={previewDrawer.open} onClose={closeEmailPreview}
                variant="persistent"
                ModalProps={{ disableBackdropClick: false, disableEscapeKeyDown: false }}
                PaperProps={{
                classes: {
                root: classes.emailPreview
                }
              }}
              >
              {previewList('right')}
              </Drawer>
              )}
              </>
  )
);
})


export default CustomEmailTemplate;