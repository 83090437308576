import React, { memo } from "react";
import Avatar from '@material-ui/core/Avatar';
import userAuthStyles from "../UserAuthStyles";
import { USERS_LABEL, ADMIN_LABEL } from '../../../../Common/Constants/constants';
import clsx from 'clsx';
import PieChart from '../PieChart'
import { userActiveStrings } from "../InsightsHelper"

const UserActivePie = ({ authStats }) => {
    const { chartData, totalUserCount } = authStats;
    const classes = userAuthStyles();
    const { avatarFallback, userCount, individualUsersAvatar, individualUsersInfoDiv, individualUsersInfoContainer, pieRoot, userActiveRoot, totalUsersh, totalUsersDiv, userAuthPie, usersInfoContainer } = classes;

    return (
        <>
            { !!chartData &&
                <div className={userActiveRoot}>
                    {!!chartData && !!chartData[0] && <>

                        <div id="userActivePie" className={userAuthPie}>
                            <PieChart dataPoints={chartData[0]} />
                        </div>
                        <div className={usersInfoContainer}>
                            <div className={totalUsersDiv}>
                                <h1 className={totalUsersh}>{ADMIN_LABEL}</h1>
                            </div>
                            <div className={individualUsersInfoContainer}>
                                {!!chartData[0] && chartData[0].map(dataItem => {
                                    const { id, value } = dataItem;
                                    return (
                                        <div id={id} className={individualUsersInfoDiv}>
                                            <Avatar classes={{ fallback: avatarFallback }} className={clsx(classes[id], individualUsersAvatar)} />
                                            {(chartData[0].length === 2) ? <span className={userCount}>{value}</span> : ''}
                                            {userActiveStrings[id]}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                    }

                    {!!chartData && !!chartData[1] && <>
                        <div id="userActivePie" className={userAuthPie}>
                            <PieChart dataPoints={chartData[1]} />
                        </div>
                        <div className={usersInfoContainer}>
                            <div className={totalUsersDiv}>
                                <h1 className={totalUsersh}>{USERS_LABEL}</h1>
                            </div>
                            <div className={individualUsersInfoContainer}>
                                {chartData[1].map(dataItem => {
                                    const { id, value } = dataItem;
                                    return (
                                        <div id={id} className={individualUsersInfoDiv}>
                                            <Avatar classes={{ fallback: avatarFallback }} className={clsx(classes[id], individualUsersAvatar)} />
                                            {(chartData[1].length === 2) ? <span className={userCount}>{value}</span> : ''}
                                            {userActiveStrings[id]}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                    }
                </div>
            }
        </>
    )
}

export default memo(UserActivePie);