import React, { useState, useEffect } from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ReactComponent as EditGroupClose } from '../../../Assests/Images/EditGroupClose.svg';
import { TableHeaderStyles, ADMINGRIDLABELTEXT, LASTSIGNEDINTEXT, USERSGRIDLABELTEXT, ACTIVE, EXPIRY } from '../../../Common/Constants/constants';
import { ReactComponent as ExternalUser } from '../../../Assests/Images/User.svg';
import SwapVertIcon from '@material-ui/icons/SwapVert';

const IOSSwitch = withStyles(TableHeaderStyles)(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function TableHeader(props) {
  const { gridType, sortData, type, isTimeout } = props;
  const [adminTabSort, setAdminTabSort] = useState(true);
  const [userTabSort, setUserTabSort] = useState(true);

  useEffect(() => {
    gridType === 0 ? sortData && sortData(adminTabSort) : sortData && sortData(userTabSort)
  }, [adminTabSort, userTabSort]);

  const sortGridData = () => {
    gridType === 0 ? setAdminTabSort(!adminTabSort) : setUserTabSort(!userTabSort);
  }
  return (
    <div className="table-head">
      <div className="table-td userIcon">
        <span className="xs-hide cursor-default">
          {<ExternalUser />}
        </span>
      </div>
      <div className="table-td">
        {(gridType === 0) ? ADMINGRIDLABELTEXT : USERSGRIDLABELTEXT}
        <SwapVertIcon className="sort-icon" onClick={sortGridData} />
      </div>
      <div className="table-td xs-hide">
        {LASTSIGNEDINTEXT}
      </div>
      <div className="table-td">
        <FormControlLabel
          disabled
          control={
            <IOSSwitch
              checked={true}
              value="checkedB"
            />
          }
          label={ACTIVE}
        />
      </div>
      {(gridType === 1 && props.isTimeout === true) &&
        <div className="table-td xs-hide userTimeout">
          <FormControlLabel control={<Checkbox disabled />} label={EXPIRY} />
        </div>
      }
      <div className="table-td">
        {type === 'group' ? <MoreVertIcon /> : <EditGroupClose />
        }
      </div>
    </div>
  );
}

export default TableHeader;
