import React, { Component } from 'react';
import downArrow from '../../../Assests/Images/arrow.svg';
import Arrow from '../../../Assests/Images/SettingsNotOpenedArrow.svg';
import './settingsStyles.scss';
import {apiGet} from '../Interceptor/apiService';
import Chip from '@material-ui/core/Chip';
import { GROUPAPPSETTINGSDETAILS, GROUPAPPCHILDSETTINGSDETAILS, LOADINGTEXT } from '../../../Common/Constants/constants';
import { encryptData } from '../../../Common/Utilities/utility';
import {CustomCheckbox} from "../CustomCheckBox";

class SettingsDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Children: [],
      groupId: this.props.groupId,
      isChildrenDisplayed: false,
      LoadingChildren: false,
      isSelected: this.props.groupData.isSelected,
      totalSelectedChildCount: this.props.groupData.totalSelectedChildCount,
      totalChildCount: this.props.groupData.totalChildCount,
      type: this.props.type,
      settingID: (this.props.settingId) ? this.props.settingId : '',
      appID: this.props.appId,
      parentId: (this.props.settingId) ? this.props.settingId : null
    };
  }

  changeCheckBoxSelection = () => {
    this.setState({
      isSelected: !this.state.isSelected
    }, () => {
      const {saveSettings,type} = this.props;
      const {appID,settingID,isSelected,parentId} = this.state;
      this.expandSettings();
      saveSettings(type, appID, settingID, isSelected, parentId, !isSelected, false);
    });
  }

  expandSettings = () => {
    const { totalChildCount, isSelected, isChildrenDisplayed, 
      Children: { length }, groupId, appID, settingID } = this.state;
    if (totalChildCount > 0 && isSelected) {
      if (!isChildrenDisplayed) {
        if (length > 0) {
          this.setState({ isChildrenDisplayed: !isChildrenDisplayed });
        }
        else {
          this.getChildSettings(groupId, appID, settingID);
          this.toggleLoading(true);
        }
      }
      else {
        this.toggleLoading(false);
      }
    }
  }

  getChildSettings = async(groupId, appId, settingId) => {
    let groupID = `?groupId=${encryptData(groupId).toString()}`;
    let appID =  groupId ? `&appId=${encryptData(appId).toString()}` : `?appId=${encryptData(appId).toString()}`;
    let settingID = `&settingId=${encryptData(settingId).toString()}`;
    let urlWithParameters;
    if(groupId){
      urlWithParameters = (this.state.type.toLowerCase() === 'setting') ? (GROUPAPPCHILDSETTINGSDETAILS + groupID + appID + settingID) : (GROUPAPPSETTINGSDETAILS + groupID + appID);
    }
    else{
      urlWithParameters = (this.state.type.toLowerCase() === 'setting') ? (GROUPAPPCHILDSETTINGSDETAILS + appID + settingID) : (GROUPAPPSETTINGSDETAILS + appID)
    }
    const {data: {appSettingChilds}} = await apiGet(urlWithParameters,this.getChildSettingsFail);
    let result = appSettingChilds.map((setting) => {
      if (this.props.unSelectByDefault) {
        setting.isSelected = this.props.isPermissionSaved("setting", appId, setting.settingId);

        this.props.saveSettings("setting", this.state.appID, setting.settingId, setting.isSelected, this.state.parentId, false, false);
        return setting;
      }
      else {
        const selectionStatus = this.props.isPermissionSelected("setting", this.state.appID, setting.settingId);
        if (selectionStatus.isSelected) {
          setting.isSelected = true;
          return setting;
        }
        else {
          if (selectionStatus.isDeselected) {
            setting.isSelected = false;
            return setting;
          }
          else {
            this.props.saveSettings("setting", this.state.appID, setting.settingId, setting.isSelected, this.state.parentId, false);
            return setting;
          }
        }
      }
    });
    this.setState({
      Children: result,
      LoadingChildren: false
    });
  }

  getChildSettingsFail = () =>
    this.setState({
      Children: [],
      LoadingChildren: false
    });

  displayChildren = () => {
    const {Children,isChildrenDisplayed,isSelected,groupId,appID,parentId} = this.state;
    if (Children !== null && Children !== undefined && Children && Children.length > 0) {
      if(isSelected && isChildrenDisplayed) {
        const {unSelectByDefault=false,TreeLevel,saveSettings,isManager,isPermissionSelected} = this.props;
        return (
          Children.map((ChildGroup, index) => (
            <SettingsDropDown
              unSelectByDefault={unSelectByDefault}
              isPermissionSaved={this.props.isPermissionSaved}
              key={index}
              TreeLevel={TreeLevel + 1}
              type="setting"
              saveSettings={saveSettings}
              isManager={isManager}
              groupData={ChildGroup}
              groupId={groupId}
              appId={appID}
              name={ChildGroup.settingName}
              settingId={ChildGroup.settingId}
              isPermissionSelected={isPermissionSelected}
              parentId={parentId}
            />
          ))
        )
      }
      else {
        this.setState({
          Children: [],
          isChildrenDisplayed: false
        });
        return null;
      }
      
    } else {
      return null;
    }
  }

  toggleLoading = (showLoader) => {
    this.setState({
      LoadingChildren: showLoader,
      isChildrenDisplayed: !this.state.isChildrenDisplayed
    });
  }

  getTreeIcon = (isSelected) => {
    if (this.state.isChildrenDisplayed)
      return (
        <div className="SettingsOpenedContainer">
          <img className="SettingsIconHover" src={downArrow} onClick={this.expandSettings} />
        </div>
      )
    else {
      return (
        <div className={(isSelected) ? "SettingsNotOpenedContainer" : "SettingsNotOpenedDisabledContainer"}>
          <img className="SettingsArrowRightIcon" src={Arrow} onClick={this.expandSettings} />
        </div>
      )
    }
  }

  displayLoadingBar = () => {
    if (this.state.LoadingChildren) {
      return <span>{LOADINGTEXT}</span>
    }
    return null;
  }

  render() {
    const {unSelectByDefault,TreeLevel,name} = this.props;
    const {isSelected,totalChildCount,totalSelectedChildCount} = this.state;
    let Indentation = 16;
    let SettingsIndentation = unSelectByDefault ? 71 : 107;
    let SettingsExpendedCellIndentation = (Indentation * TreeLevel) + 'px';
    if (TreeLevel == 0) {
      Indentation = 0;
      SettingsExpendedCellIndentation = SettingsIndentation + 'px';
    }
    return (
      <div className="settingsli" style={{ paddingLeft: Indentation + "px" }}>
        <div className="setting">
          <div>
            <CustomCheckbox
              color="default"
              checked={isSelected}
              onClick={this.changeCheckBoxSelection}
            />
          </div>
          <div
            className="SettingsExpendedCell"
            onClick={this.expandSettings}
            style={{ width: `calc(100% - ${SettingsExpendedCellIndentation})` }}>
            {name}
          </div>
          {(totalChildCount > 0 && !unSelectByDefault) &&
            <div className="ChipContainer">
              {(totalChildCount > 0) ?
                <Chip
                  label={totalSelectedChildCount}
                  onClick={this.expandSettings}
                  className="SelectedCountChip"
                />
                :
                <Chip
                  label={totalSelectedChildCount}
                  className="SelectedCountChip"
                />}
              <div className="CountDivider">/</div>
              <Chip label={totalChildCount} className="TotalCountChip" />
            </div>
          }

          {(totalChildCount > 0) && this.getTreeIcon(isSelected)}
        </div>
        {this.displayLoadingBar()}
        {this.displayChildren()}
      </div>
    )
  }
}

export default SettingsDropDown;