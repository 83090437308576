import React from 'react';
import Button from '@material-ui/core/Button';
import customButtonStyles from "./CustomButtonStyles";

export const CustomButton = ({isButtonDisabled=true,onClick,buttonLabel}) => {
    const {customButtonRoot} = customButtonStyles();
    const onClickHandler = (event) => {
        onClick && onClick(event); 
    }
    return (
        <Button disabled={isButtonDisabled} variant="contained" disableRipple classes={{ root: customButtonRoot }} onClick={onClickHandler} >
            {buttonLabel || ""}
        </Button>
    )
}