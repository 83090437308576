import { makeStyles } from '@material-ui/core/styles';

const CopySnippetStyles = makeStyles((theme) => ({
    copyIcon: { 
        position: 'absolute',
        right: '10px',
        top: '6px',
    },
    copySnippet: {
        position: 'relative'
    }
}));

export default CopySnippetStyles;
