import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from '@material-ui/core/styles';
import DialogBox from "./dialog";
import { OptionMenuStyles, DELETETEXT } from '../../../Common/Constants/constants';
import { intlShape, injectIntl } from "react-intl";

const styles = theme => OptionMenuStyles;

class DeletePopUp extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      anchorEl: null,
      opennow: false
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCloseDialogue = () => {
    this.setState({ opennow: false });
    this.props.deleteHandler();
  };

  onCloseEvent = (props) => {
    this.setState({ opennow: false })
  }

  handleDelete = () => {
    if(typeof(this.props.isDisable) !== 'undefined' && this.props.isDisable != null)
      this.setState({ opennow: !this.props.isDisable, anchorEl: null });
    else
      this.setState({ opennow: true, anchorEl: null });
  }

  render() {
    
    const { classes } = this.props;
    const { anchorEl } = this.state;
    let isDisable = false;
    if(typeof(this.props.isDisable) !== 'undefined' && this.props.isDisable != null){
      isDisable = this.props.isDisable;
    }

    return (
      <div>
        <Button
          aria-owns={anchorEl ? "simple-menu" : null}
          aria-haspopup="true"
          onClick={this.handleDelete.bind(this)}          
          className={(isDisable)? classes.buttonDisable : null } 
          classes={{ root: classes.optionsButton, text: classes.optionsButtonText }}
        >
          {DELETETEXT}
        </Button>
        <DialogBox dialogHead={this.props.header} dialogContent={this.props.content} 
        dialogButtonText={DELETETEXT} opennow={this.state.opennow} 
        onCloseClick={this.handleCloseDialogue} onCloseEvent={this.onCloseEvent} />
      </div>
    );
  }
}

DeletePopUp.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(styles)(DeletePopUp));
