import { NOUSERTEXT, AUTHENTICATED, NOT_ACTIVE, PENDING } from '../../../Common/Constants/constants';

const colors = { 'autheticated': '#33ca80', 'notActive': '#d73a49', 'pending': 'yellow', 'noUsers': '#bdbdbd', 'admins': '#33ca80', 'users': '#d73a49' }
const strings = { 'autheticated': AUTHENTICATED, 'notActive': NOT_ACTIVE, 'pending': PENDING, 'noUsers': NOUSERTEXT, 'admins': 'admins', 'users': 'users' }
const getColor = bar => colors[bar.id]
const getChartColor = () => "#33ca80"
const userActiveColors = { 'autheticated': '#33ca80', 'notActive': '#d73a49', 'pending': 'yellow', 'noUsers': '#bdbdbd' }
const userActiveStrings = { 'autheticated': 'active', 'notActive': 'not active', 'noUsers': NOUSERTEXT }
const getUserActiveColor = bar => userActiveColors[bar.id]
const getUserActiveChartColor = () => "#33ca80"
const pieChartProps = {
    defs: [
        {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
        },
        {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
        }
    ],
    fills: [
        {
            match: {
                id: 'ruby'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'c'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'go'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'python'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'scala'
            },
            id: 'lines'
        },
        {
            match: {
                id: 'lisp'
            },
            id: 'lines'
        },
        {
            match: {
                id: 'elixir'
            },
            id: 'lines'
        },
        {
            match: {
                id: 'javascript'
            },
            id: 'lines'
        }
    ]
}
const barChartProps = {
    defs: [
        {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true
        },
        {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
        }
    ],
    fills: [
        {
            match: {
                id: 'fries'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'sandwich'
            },
            id: 'lines'
        }
    ],
    theme: {
        tooltip: {
            container: {
                padding: 0,
            },
        },
    },
    axisLeft: {
        tickSize: 0,
        tickPadding: 0,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -43,
        format: axisValue => Math.floor(axisValue) === axisValue && axisValue
    }
}

const barChartWidth = (dataLength) => {
  let val = "";
  if(dataLength <= 5){
    val = "25%";
  }
  else if(dataLength > 5 && dataLength <=20){
    val = "50%"
  }
  else if(dataLength > 20 && dataLength <=50){
    val = "75%"
  }
  else {
    val = "100%"
  }
  return val;
}

export {
    getColor,
    colors,
    strings,
    getChartColor,
    getUserActiveColor,
    userActiveColors,
    userActiveStrings,
    getUserActiveChartColor,
    pieChartProps,
    barChartProps,
    barChartWidth
}