import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { intlShape, injectIntl } from "react-intl";
import { injectIntlTranslation } from "../../../Common/Utilities/utility";
import { OptionMenuDialogStyles, CONFIRMATIONTEXT, CANCELTEXT,TEMPLATENAME } from '../../../Common/Constants/constants';

const useStyles = makeStyles(OptionMenuDialogStyles);

function DialogBox(props) {
  const {children, opennow, dialogHead, dialogContent, dialogDisclaimer, isDisabled, dialogButtonText, onCloseClick, onCloseEvent,saveTemplate,onSaveTemplate,duplicateTemplateMessage} = props;
  const classes = useStyles();
  const [savetemplateEnable, setSaveTemplateEnable] = useState(true);
   const [overrideDisable, setOverrideDisabled] = useState(false);

   useEffect(()=>{
    if((props.selectedTemplate!=null || props.selectedTemplate!=undefined) && props.selectedTemplate.id==1)
    setOverrideDisabled(true)
    else
    setOverrideDisabled(false)
  },[props.selectedTemplate]);

  const enableSaveTemplate=({ target: { value } })=>{
    if(value!="")
    {
      setSaveTemplateEnable(false)
      props.templateValue(value)
    }
    else{
      setSaveTemplateEnable(true)
    }
  }

 const createUserField = (intl) => {
    const userPropsForName = {
      placeholder: injectIntlTranslation(intl, TEMPLATENAME),
      onChange: enableSaveTemplate
    };

   const clearState = () => {
      setSaveTemplateEnable(true)
    }

    props.passClearStateFunc(clearState);

    return (
      <>
      <TextField
            label="Template Name"
            required
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              },
              required: false,
              shrink: true,
            }}
            autoComplete="off"
            className={classes.templateName}
            margin="normal"
            {...userPropsForName} />
            {(duplicateTemplateMessage!=''?<span className={classes.errorText}> {duplicateTemplateMessage}</span>:'')}
            </>
    )
  }

  return (
    <div>
      <Dialog
        open={opennow}
        onClose={onCloseEvent}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.root }}
      >
        <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}> { dialogHead}  
        {(saveTemplate)?
        <IconButton
          aria-label="close"
          onClick={onCloseEvent}
          className={classes.closeDialog}
        >
          <CloseIcon />
        </IconButton>:<></>}
        </DialogTitle>
       
        {(dialogContent)?
        <DialogContent id="addbg">
          { children? children : <DialogContentText classes={{ root: classes.deletetitle }}>
          {dialogContent}
          {(saveTemplate) ?<div>
            {createUserField(props.intl)}
          </div>:<>
          <br />   <br />
          ({dialogDisclaimer ?  dialogDisclaimer : CONFIRMATIONTEXT})</>}
          </DialogContentText>}
        </DialogContent>:<DialogContent id="addbg">
          { children? children : <DialogContentText classes={{ root: classes.deletetitle }}>
          {dialogContent}
          <br />   <br />
            {(dialogDisclaimer) ?  dialogDisclaimer : CONFIRMATIONTEXT}
          </DialogContentText>}
        </DialogContent>} 
        
        <DialogActions id="dialogbutton" className= {classes.dialogActions}>
          {(isDisabled) ?
            <>
            <Button disabled color="secondary" variant="outlined" className={classes.actionButton}>
              {dialogButtonText}
            </Button>
            <Button disabled color="secondary" variant="outlined" className={classes.actionButton}>
            {CANCELTEXT}
            </Button>
            </>
          :
            <>
            <Button disabled={overrideDisable?true:false} onClick={onCloseClick} color="primary" variant="outlined" className={classes.actionButtonAdd} >
              {dialogButtonText}
            </Button>
            {
              (saveTemplate)?
              <Button disabled={savetemplateEnable?true:false} onClick={(saveTemplate) ? onSaveTemplate : onCloseEvent} color="primary" variant="outlined" autoFocus className={classes.actionButton}>
            {(saveTemplate) ?  saveTemplate : CANCELTEXT}
            </Button>:
            <Button onClick={onCloseEvent} color="primary" variant="outlined" autoFocus className={classes.actionButton}>
              {CANCELTEXT}
            </Button>
            }
            </>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogBox.propTypes = {
  fullScreen: PropTypes.bool.isRequired,

};

export default withMobileDialog()(DialogBox);