import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as EditClose } from '../../../Assests/Images/EditGroupClose.svg';
import appConfig from '../../../Environment/environments';
import { EmailPreview,EMAILPREVIEW,INVITATION,INVITATION_COLLABORATION,ACCEPT_INVITATION,INTERNAL_USER,EMAIL_SENTTO,SUPPORT,THANKS,CARRIERAPPSHUB } from '../../../Common/Constants/constants';

const useStyles = makeStyles(EmailPreview, { withTheme: true });

function EmailTemplatePreview(props) {
    const classes = useStyles();
    const [emailData, setEmailData] = useState({});

    useEffect(() => {
       
        SetCustomTemplate(props.templateData); //children function of interest
    }, [props.templateData]);

    const SetCustomTemplate=(templateData)=>{
        setEmailData(templateData);
    }
    return (
        <>
        <div className={classes.previewTitle}>
        <p className={classes.previewDisplayTitle}>{EMAILPREVIEW}
          <span className={classes.closeIcon} onClick={props.onClick}>
            <EditClose></EditClose>
          </span>
        </p>
      </div>
    <div className={classes.parentDiv}>
        <table className={classes.parentTable}>
        <tr>
            <td width="8%" style={{ backgroundColor: "#F4F4F4" }} />
            <td className={classes.parentTd}>
            <img className={classes.iconImage} key={Date.now()} id="carrierLogo" src={(emailData.bannerExt!='' && emailData.icon=='')?(appConfig.api.emailUrl+emailData.id+"."+emailData.bannerExt)+'?v='+new Date().getTime():(emailData.icon?emailData.icon:appConfig.api.emailUrl+"Header.png"+'?v='+new Date().getTime())} />
            <br />
            <br />
            <center>
                <table width="90%" border="0" cellspacing="0" cellpadding="0">
                <tr border="0" cellspacing="0" cellpadding="0">
                    <td>
                    <div style={{ textAlign: "left", lineHeight: "24px" }}>
                        <div style={{ paddingTop: "0px" }}>
                        {(emailData.title ?
                        emailData.title
                        :<>
                        <a href="mailto:@@InviterEmail" style={{ textDecoration: "none" }}>
                            <span style={{ color: "#1891F6", fontWeight: "bold" }}>
                            {props.loggedInUserName}
                            </span>
                        </a>{" "}
                        {INVITATION}
                        <span style={{ fontWeight: "bold",marginLeft:"2px" }}>{props.groupName}</span> group.
                        </>
                        )}
                        </div>
                        <br />
                        <div style={{ backgroundColor: "#E0ECF8", fontSize: "12px", marginBottom: "8px", wordBreak:"break-word" }}>                        
                        {emailData.body}
                        </div>
                        <div style={{ fontSize: "14px" }}>
                        {INVITATION_COLLABORATION}
                        </div>
                        <br />
                        <div>
                        <center>
                            <a
                           className={classes.acceptInvitation}
                            >
                            {ACCEPT_INVITATION}
                            </a>
                            <br />
                        </center>
                        </div>
                        <br />
                        <div style={{ fontSize: "14px", marginBottom: "6px" }}>
                        {INTERNAL_USER}
                        </div>
                    </div>
                    <center>
                        <table width="100%">
                        <tr border="0" cellspacing="0" cellpadding="0">
                            <td>
                            <hr height="0.5" style={{ height: "0.5px", border: "0 none", color: "#D7DFE3", backgroundColor: "#D7DFE3" }}
                            />
                            </td>
                        </tr>
                        </table>
                        <br />
                        <div style={{ fontSize: "12px",wordBreak:"normal" }}>
                        <div>
                           {EMAIL_SENTTO}  <a href="mailto:@@Support">{props.sendEmailTo}</a> from{" "}
                            <a href="@@AppUrl" target="_blank">
                            {CARRIERAPPSHUB}
                            </a>
                            .
                        </div>
                        <div>
                            {SUPPORT}{" "}
                            <a href="mailto:@@Support">{emailData.supporEmail}</a>
                        </div>
                        <div style={{ fontWeight: "bold", fontSize: "12px", margin: "8px 32px" }}>
                            {THANKS}
                        </div>
                        </div>
                    </center>
                    </td>
                </tr>
                </table>
            </center>
            </td>
            <td width="8%" style={{ backgroundColor: "#F4F4F4" }} />
        </tr>
        </table>
    </div>
    </>
    );
}

export default EmailTemplatePreview;
