import React, { useState } from 'react';
import ToastMessages from '../../../Shared/Toast/ToastMessages';
import { COPIED, COPYFAILED } from '../../../../Common/Constants/constants';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import useCopySnippetStyles from './CopySnippetStyles';

export default function CopySnippet(props){
    const [copied, setCopied] = useState(false);
    const [variant, setVariant] = useState('');
    const [copyMsg, setCopyMsg] = useState('');
    const { copyIcon, copySnippet } = useCopySnippetStyles();

    function handleToast(event, reason) {
        if (reason === 'clickaway') {
          return;
        }
        setCopied(false);
    }

    const copyToClipBoard = async copyMe => {
        try {
          await navigator.clipboard.writeText(copyMe);
          setVariant("success");
          setCopyMsg(COPIED);
          setCopied(!copied);
        } catch (err) {
          setVariant("error");
          setCopyMsg(COPYFAILED);
          setCopied(!copied);
        }
    };

    return (
        <div className={copySnippet}>
            <ToastMessages statusMessage={copied}
            message={copyMsg}
            variant={variant}
            toastHPosition={'center'}
            toastVPosition={'top'}
            close={handleToast} 
            />
            <FileCopyIcon className={copyIcon} onClick={() => copyToClipBoard(props.copyCode)} />
            <SyntaxHighlighter language="javascript" style={docco}>
                {props.copyCode}
            </SyntaxHighlighter> 
        </div>
    );    
}
