import { SET_PARENT_GROUP_FILTER, SET_USER_SELECTION, SET_USER_PERMISSIONS } from '../../Common/Constants/constants';

const initialState = {
    parentGroupFilter : {},
    usersList: [],
    userPermissions: [],
    AppsSelection: []
};

export default function filterReducer(state = initialState, action){
    switch(action.type){
        case SET_PARENT_GROUP_FILTER:
            return {
                ...state,
                parentGroupFilter: action.data.parentGroupFilter,
            }
        case SET_USER_SELECTION:
            return {
                ...state,
                usersList: action.data.usersList
            }
        case SET_USER_PERMISSIONS:
            return {
                ...state,
                userPermissions: action.data.userPermissions,
                AppsSelection: action.data.AppsSelection
            }
        default:
            return state;
    }
}
