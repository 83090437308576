import React from "react";
import Paper from "@material-ui/core/Paper";
import { injectIntlTranslation } from "../../../Common/Utilities/utility";
import { intlShape, injectIntl } from "react-intl";
import { ReactComponent as EditClose } from "../../../Assests/Images/EditGroupClose.svg";
import { withStyles } from "@material-ui/core/styles";
import { AddGroupStyles } from "../../../Common/Constants/constants";
import TextField from "@material-ui/core/TextField";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import LaunchIcon from "@material-ui/icons/Launch";
import RightDrawer from "../../Shared/RightDrawer/RightDrawer";
import EditGroup from "../../Groups/edit-group/edit-group";
import { getAuditDetails } from "../Audit/AuditDetailsHelper";
import ToastMessages from "../../Shared/Toast/ToastMessages";
import {
  ACTIONDETAILS,
  ADMINISTRATORACTION,
  IMPACTEDUSER,
  ACTION,
} from "../../../Common/Constants/constants";

class AuditDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editGroupId: 0,
      groupListDetails: {},
      right: false,
      fullScreen: false,
      messageVariant: "",
      message: "",
      statusMessage: false,
      toastVPosition: "top",
      toastHPosition: "center",
      actionId: 0,
      IsDeleted:0,
      affectedGroup: "",
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  componentDidMount = () =>
    getAuditDetails(
      this.props.expandedAuditDetails.id,
      this.getChildrenSuccess
    );

  getChildrenSuccess = (responseData) => {
    if (responseData) {
      this.setState({
        groupListDetails: responseData.groupList,
        actionId: responseData.actionId,
        affectedGroup: responseData.affectedEntityDesc,
        IsDeleted:responseData.isDeleted
      });
    }
  };

  getTranslatedString = (constantName) => {
    return injectIntlTranslation(this.props.intl, constantName);
  };

  onClickEditGroup = (group) => {
    this.setState({ editGroupId: group.value });
    this.setState({
      fullScreen: true,
    });
  };

  toggleDrawer = (side, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (side === "right") {
      this.setState({
        fullScreen: false,
        right: false,
      });
    }
  };

  deleteGroupHandler = (statusMessage, message, messageVariant) => {
    this.setState({
      statusMessage: statusMessage,
      message: message,
      messageVariant: messageVariant,
      fullScreen: false,
      loading: true,
    });
    getAuditDetails(
      this.props.expandedAuditDetails.id,
      this.getChildrenSuccess
    );
  };

  handleToast(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      statusMessage: false,
    });
  }

  onClose=()=>{
       this.setState({
        right: false,
       });
      this.props.onClose();
   }

  render() {
    const {
      name,
      username,
      adminName,
      email,
    } = this.props.expandedAuditDetails;
    const { classes, intl } = this.props;
    return (
      <div className={classes.root}>
        <div>
          <Paper
            square={true}
            classes={{
              root: classes.paperSize,
              elevation1: classes.editGroupPaper,
            }}
          >
            <div className={classes.editTitle}>
              <p className={classes.editGroupTitle}>
                {ACTIONDETAILS}
                <span
                  className={classes.closeIcon}
                  onClick={this.props.onDrawerClose}
                >
                  <EditClose></EditClose>
                </span>
              </p>
            </div>
            {this.props.expandedAuditDetails ? (
              <>
                <div>
                  <TextField
                    className={classes.auditDetails}
                    disabled
                    id="actionsDetails"
                    label={ACTION}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    multiline
                    value={name}
                  />
                </div>
                <div>
                  <TextField
                    className={classes.auditDetails}
                    disabled
                    id="actionsDetailsAdmin"
                    label={ADMINISTRATORACTION}
                    variant="outlined"
                    size="small"
                    multiline
                    InputLabelProps={{ shrink: false }}
                    value={adminName}
                  />
                </div>
                <div>
                  <TextField
                    className={classes.auditDetails}
                    disabled
                    id="actionsImpactedUser"
                    label={IMPACTEDUSER}
                    variant="outlined"
                    size="small"
                    multiline
                    InputLabelProps={{ shrink: false }}
                    value={
                      username
                        ? username.trim().length > 0
                          ? username
                          : email
                        : email
                    }
                  />
                </div>
                <div className={classes.groupInfoHeader}>Groups</div>
                {this.state.groupListDetails.length > 0 ? (
                  <>
                    {this.state.groupListDetails.map((group) => (
                      <div className={`table-body userEmail table-body-white`}>
                        <div className={classes.auditGroupDetailsRow}>
                          <div className={classes.groupDetailLabel}>
                            {this.state.actionId !== 3 && this.state.IsDeleted!==1 ? (
                              <a
                                href="#"
                                className={classes.groupLaunchAnchor}
                                onClick={() => this.onClickEditGroup(group)}
                              >
                                <span>
                                  <LaunchIcon
                                    className={classes.launchIcon}
                                  ></LaunchIcon>
                                </span>
                                <span>{group.label}</span>
                              </a>
                            ) : (
                              <>
                                <span>{this.state.affectedGroup}</span>
                              </>
                            )}
                            <span className={this.state.actionId !== 3 && this.state.IsDeleted!==1 ?classes.breadcrumbSpan:classes.deleteBreadcrumbSpan}>
                              <Breadcrumbs
                                maxItems={group.hierarchy.length}
                                separator=">"
                                aria-label="breadcrumb"
                              >
                                {group.hierarchy.map((item) => (
                                  <Typography key="1" color="text.primary">
                                    {item.label}
                                  </Typography>
                                ))}
                              </Breadcrumbs>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Paper>
        </div>
        <ToastMessages
          statusMessage={this.state.statusMessage}
          message={this.state.message}
          variant={this.state.messageVariant}
          toastHPosition={this.state.toastHPosition}
          toastVPosition={this.state.toastVPosition}
          close={this.handleToast.bind(this)}
        />
        <RightDrawer fullScreen open={this.state.fullScreen}>
          <EditGroup
            id={this.state.editGroupId}
            deleteGroupHandler={this.deleteGroupHandler}
            onClose={this.onClose}
          />
        </RightDrawer>
      </div>
    );
  }
}

export default injectIntl(withStyles(AddGroupStyles)(AuditDetails));
