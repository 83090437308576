import React from 'react';


function authcallback() {
  return (
    <div className="App">
      
    </div>
  );
}

export default authcallback;
